import DocListView from 'volto-yc-documents/components/ListingViews/DocList';
import CPLDocList from 'volto-yc-documents/components/ListingViews/CPLDocList';
import ChildcareCenterDocList from 'volto-yc-documents/components/ListingViews/ChildcareCenterDocList';
import HomePage from 'volto-yc-documents/components/HomePage';
import Unauthorized from 'volto-yc-documents/components/Unauthorized';
import ContentListingView from 'volto-yc-documents/components/ContentTypesViews/ContentListingView';
import FinancialAidListingView from 'volto-yc-documents/components/ContentTypesViews/FinancialAidListingView';
import GraduateListingView from 'volto-yc-documents/components/ContentTypesViews/GraduateListingView';
import STListingView from 'volto-yc-documents/components/ContentTypesViews/STListingView';
import HealthListingView from 'volto-yc-documents/components/ContentTypesViews/HealthListingView';
import RegistrarListingView from 'volto-yc-documents/components/ContentTypesViews/RegistrarListingView';
import OSASListingView from 'volto-yc-documents/components/ContentTypesViews/OSASListingView';
import OTListingView from 'volto-yc-documents/components/ContentTypesViews/OTListingView';
import CSDListingView from 'volto-yc-documents/components/ContentTypesViews/CSDListingView';
import YECAListingView from 'volto-yc-documents/components/ContentTypesViews/YECAListingView';
import AdmissionsListingView from 'volto-yc-documents/components/ContentTypesViews/AdmissionsListingView';
import BursarListingView from 'volto-yc-documents/components/ContentTypesViews/BursarListingView';
import ChildcareCenterListingView from 'volto-yc-documents/components/ContentTypesViews/ChildcareCenterListingView';
import HRListingView from 'volto-yc-documents/components/ContentTypesViews/HRListingView';
import LearningListingView from 'volto-yc-documents/components/ContentTypesViews/LearningListingView';
import ProvostListingView from 'volto-yc-documents/components/ContentTypesViews/ProvostListingView';
import ReliefFundListingView from 'volto-yc-documents/components/ContentTypesViews/ReliefFundListingView';
import SEEKListingView from 'volto-yc-documents/components/ContentTypesViews/SEEKListingView';
import TRIOListingView from 'volto-yc-documents/components/ContentTypesViews/TRIOListingView';
import AthleticsListingView from 'volto-yc-documents/components/ContentTypesViews/AthleticsListingView';
import VAListingView from 'volto-yc-documents/components/ContentTypesViews/VAListingView';
import CPLListingView from 'volto-yc-documents/components/ContentTypesViews/CPLListingView';
import HempsteadECHSEnrollmentListingView from 'volto-yc-documents/components/ContentTypesViews/HempsteadECHSEnrollmentListingView';
// import ScholarshipListingView from 'volto-yc-documents/components/ContentTypesViews/ScholarshipListingView';
import item_count from 'volto-yc-documents/reducers/item_count/item_count';
import raw from 'volto-yc-documents/reducers/raw/raw';
import value from 'volto-yc-documents/reducers/value/value';

export default function applyConfig(config) {
  config.addonReducers.item_count = item_count;
  config.addonReducers.raw = raw;
  config.addonReducers.value = value;
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      'academic-affairs': ProvostListingView,
      admissions: AdmissionsListingView,
      admissions_docs: DocListView,
      athletics: DocListView,
      bursar: BursarListingView,
      bursar_docs: DocListView,
      change_of_grade: DocListView,
      childcare_center: ChildcareCenterListingView,
      childcare: ChildcareCenterDocList,
      cpl: CPLDocList,
      'financial-aid': FinancialAidListingView,
      'Financial Aid': FinancialAidListingView,
      'Financial Aid Docs': DocListView,
      finaid: FinancialAidListingView,
      finaid_docs: DocListView,
      health: HealthListingView,
      health_docs: DocListView,
      hr: HRListingView,
      hr_docs: DocListView,
      learning: LearningListingView,
      learning_docs: DocListView,
      provost: ProvostListingView,
      registrar: RegistrarListingView,
      registrar_docs: DocListView,
      relief_fund: ReliefFundListingView,
      relief_fund_docs: DocListView,
      seek: SEEKListingView,
      seek_docs: DocListView,
      trio: TRIOListingView,
      trio_forms: DocListView,
      eoc: DocListView,
      ot: DocListView,
      osas: OSASListingView,
      osas_docs: DocListView,
      csd: DocListView,
      student_activities: STListingView,
      student_activity: DocListView,
      scholarship: DocListView,
      'hempstead-echs-enrollment-form': DocListView,
      va: DocListView,
      yeca: DocListView,
      'Plone Site': HomePage,
      view: DocListView,
      psid: DocListView,
      it: DocListView,
    },
    errorViews: {
      ...config.views.errorViews,
      401: Unauthorized,
    },
    layoutViews: {
      ...config.views.layoutViews,
      cpl_view: CPLListingView,
      fin: FinancialAidListingView,
      hea: HealthListingView,
      registrar_view: RegistrarListingView,
      osas_view: OSASListingView,
      ot_view: OTListingView,
      csd_view: CSDListingView,
      yeca_view: YECAListingView,
      st_view: STListingView,
      'hempstead-echs-enrollment': HempsteadECHSEnrollmentListingView,
      athletics_view: AthleticsListingView,
      generic_listing_view: ContentListingView,
      // 'fin-aid-view': FinancialAidListingView,
      // 'section-view': ContentListingView,
      // 'student-view': ContentListingView,
      grad_prog_view: GraduateListingView,
      va_view: VAListingView,
    },
  };
  return config;
}

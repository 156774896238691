import React from 'react';
import DataGridFieldWidget from 'volto-yc-datagridfield/Widgets/DataGridFieldWidget';

const ItemSchema = {
  title: 'Please list three references who can speak highly on your behalf.',
  description:
    'Under the Family Educational Right to Privacy Act of 1974 (FERPA) (20 U.S.C. 1232g; 34 CFR Part 99) which gives students the right to inspect and review their educational records, students may waive their right to see specific confidential statements and letters of recommendations. In the belief that applicants, and the person from whom they request recommendations, may wish to preserve the confidentiality of those recommendations,',
  properties: {
    name: {
      title: 'Name',
      description: 'Recommender',
      type: 'string',
      factory: 'Text line (String)',
    },
    email: {
      title: 'Email',
      type: 'string',
      factory: 'Text line (String)',
    },
    ioc: {
      title: 'Institution/Organization/Company',
      factory: 'Text line (String)',
      type: 'string',
    },
    address: {
      title: 'Address',
      factory: 'Text line (String)',
      type: 'string',
    },
    phoneNumber: {
      title: 'Telephone Number',
      factory: 'Text line (String)',
      type: 'string',
    },
    relationship: {
      title: 'Relationship to You',
      factory: 'Text line (String)',
      type: 'string',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'References',
      fields: [
        'name',
        'email',
        'ioc',
        'address',
        'phoneNumber',
        'relationship',
      ],
    },
  ],
  required: [],
};

const ReferencesWidget = (props) => {
  return (
    <DataGridFieldWidget
      schema={ItemSchema}
      {...props}
      value={(props.value || props.default) || (props.value?.items || props.default?.items) || []}
      onChange={((id, value) => props.onChange(id, value)) || ((id, value) => props.onChange(id, {items: value}))}
    />
  );
};

export default ReferencesWidget;

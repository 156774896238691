import moment from 'moment';
import { Container, Form, Table, Grid } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
// import { PDFViews, ContentStatusHistory } from '../index';
import PDFViews from 'volto-yc-documents/components/PDFViews/PDFViews';
import ContentStatusHistory from 'volto-yc-documents/components/ContentStatusHistory/ContentStatusHistoryCTV';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import YCImageView from 'volto-yc-documents/components/YCImageView/YCImageView';
import Voting from 'volto-yc-voting/components/Voting/Voting';
import DocumentSelector from 'volto-yc-graduateapplication/components/ContentTypesViews/DocumentSelector';
import { searchContent } from '@plone/volto/actions/search/search';
import { useSelector, useDispatch } from 'react-redux';
import DocsPortalImage from 'volto-yc-documents/components/documents-portal-login.svg';
import DefaultLoader from 'volto-yc-graduateapplication/components/ContentTypesViews/DefaultLoader';

const FieldSelector = ({ label, field }) => {
  if (label && field) {
    return (
      <div>
        <br />
        <strong> {field && label} </strong>: {field && field}
        <br />
      </div>
    );
  }
  return <span>{field && field}</span>;
};
const AttachmentSelector = ({ type, file }) => {
  const content = useSelector((store) => store.content.data);
  return (
    <div>
      {content.preview === 'True' ? (
        <div>
          <p>
            <strong> Attachment: </strong>
            {(type && type?.title) ||
              (type && type) ||
              (type && type?.toString()) ||
              (type && type?.token)}{' '}
            - <a href={file?.download}>{file?.filename}</a>
          </p>
          {file ? (
            <PDFViews file={file?.download} type={file['content-type']} />
          ) : (
            <YCImageView file={file?.scales?.preview?.download} />
          )}{' '}
        </div>
      ) : (
        <p>
          <strong> Attachment: </strong>
          {(type && type?.title) ||
            (type && type) ||
            (type && type?.toString()) ||
            (type && type?.token)}{' '}
          - {file?.filename}
        </p>
      )}
    </div>
  );
};

const SchoolWidget = ({ school }) => {
  return (
    __CLIENT__ && (
      <Table celled className="igibschool_list">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="School" defaultMessage="School" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="Location" defaultMessage="Location" />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="Year of Graduation"
                defaultMessage="Year of Graduation"
              />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="GPA" defaultMessage="GPA" />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="Major" defaultMessage="Major" />{' '}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {school &&
            school?.map((item) => (
              <Table.Row key={item.school}>
                <Table.Cell>{item.school}</Table.Cell>
                <Table.Cell>{item.location}</Table.Cell>
                <Table.Cell>{item.year}</Table.Cell>
                <Table.Cell>{item.gpa}</Table.Cell>
                <Table.Cell>{item.major}</Table.Cell>
              </Table.Row>
            ))}
          {school &&
            school?.items?.map((item) => (
              <Table.Row key={item.school}>
                <Table.Cell>{item.school}</Table.Cell>
                <Table.Cell>{item.location}</Table.Cell>
                <Table.Cell>{item.year}</Table.Cell>
                <Table.Cell>{item.gpa}</Table.Cell>
                <Table.Cell>{item.major}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    )
  );
};

const EmployerWidget = ({ employer }) => {
  return (
    __CLIENT__ && (
      <Table celled className="igibemployer_list">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage
                id="Employer Name"
                defaultMessage="Employer Name"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="Employer Phone"
                defaultMessage="Employer Phone"
              />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="Street" defaultMessage="Street" />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="City" defaultMessage="City" />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="State" defaultMessage="State" />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="Postal Code" defaultMessage="Postal Code" />{' '}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {employer &&
            employer?.map((item) => (
              <Table.Row key={item.employer}>
                <Table.Cell>{item.employer}</Table.Cell>
                <Table.Cell>{item.employerPhone}</Table.Cell>
                <Table.Cell>{item.street}</Table.Cell>
                <Table.Cell>{item.city}</Table.Cell>
                <Table.Cell>{item.state}</Table.Cell>
                <Table.Cell>{item.zip}</Table.Cell>
              </Table.Row>
            ))}
          {employer &&
            employer?.items?.map((item) => (
              <Table.Row key={item.employer}>
                <Table.Cell>{item.employer}</Table.Cell>
                <Table.Cell>{item.employerPhone}</Table.Cell>
                <Table.Cell>{item.street}</Table.Cell>
                <Table.Cell>{item.city}</Table.Cell>
                <Table.Cell>{item.state}</Table.Cell>
                <Table.Cell>{item.zip}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    )
  );
};

const ReferencesWidget = ({ references }) => {
  return (
    __CLIENT__ && (
      <Table celled className="igibreferences_list">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="Name" defaultMessage="Name" />{' '}
              <em>
                <FormattedMessage
                  id="Recommender"
                  defaultMessage="Recommender"
                />{' '}
              </em>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="Email" defaultMessage="Email" />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="Institution/Organization/Company"
                defaultMessage="Institution/Organization/Company"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="Address" defaultMessage="Address" />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="Telephone Number"
                defaultMessage="Telephone Number"
              />{' '}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="Relationship to You"
                defaultMessage="Relationship to You"
              />{' '}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {references &&
            references?.items?.map((item) => (
              <Table.Row key={item.name}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
                <Table.Cell>{item.ioc}</Table.Cell>
                <Table.Cell>{item.address}</Table.Cell>
                <Table.Cell>{item.phoneNumber}</Table.Cell>
                <Table.Cell>{item.relationship}</Table.Cell>
              </Table.Row>
            ))}
          {references &&
            references?.map((item) => (
              <Table.Row key={item.name}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
                <Table.Cell>{item.ioc}</Table.Cell>
                <Table.Cell>{item.address}</Table.Cell>
                <Table.Cell>{item.phoneNumber}</Table.Cell>
                <Table.Cell>{item.relationship}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    )
  );
};

const YesRecommendations = () => {
  const content = useSelector((store) => store.content.data);
  const dispatch = useDispatch();
  let location = useLocation();
  const results = useSelector(
    (state) => state.search.subrequests.content?.items,
  );
  React.useEffect(() => {
    dispatch(
      searchContent(
        '',
        {
          portal_type: 'recommendations',
          metadata_fields: ['refereeName', 'acceptanceAR', 'acceptanceNR'],
          path: location.pathname,
        },
        'content',
      ),
    );
  }, [content, dispatch, location.pathname]);

  return (
    <div>
      <h2>Recommendations</h2>
      {results && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage
                  id="Recommender"
                  defaultMessage="Recommender"
                />{' '}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="Recommendation"
                  defaultMessage="Recommendation"
                />{' '}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {results &&
              results.map((item) => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Link
                      title={item['@type'] && item.refereeName}
                      to={item['@id']}
                    >
                      {item['@type'] && item.refereeName}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {(item['@type'] && item?.acceptanceAR) ||
                      (item['@type'] && item?.acceptanceNR)}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

const NoRecommendations = () => {
  return <div>No Recommendations Found</div>;
};

const Recommendations = () => {
  const hasRecommendations = React.useState(true);
  return hasRecommendations ? <YesRecommendations /> : <NoRecommendations />;
};

const print = () => window.print();
const ApplicationForm = (props) => {
  const { content } = props;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return content.last_name ? (
    <Container className="view-wrapper">
      {!loaded ? (
        <DefaultLoader active={!loaded} />
      ) : (
        <Form>
          <title>Graduate Application</title>
          <h1 className="documentFirstHeading">
            {content.last_name}, {content.first_name}{' '}
            {content.employeeID && <span>- {content.employeeID} </span>}-{' '}
            {content.program?.token.toString()} -{' '}
            {content.semester?.token.toString()}
          </h1>
          <span className="contentStatusHistory">
            <span>
              <ContentStatusHistory {...content} />
            </span>
          </span>
          <span> | </span>
          <span>
            <a href="#" onClick={print}>
              Print
            </a>
          </span>
          <Recommendations hasRecommendations={true} />
          <Voting />
          {content.comments && (
            <FieldSelector
              label={"Reviewer's Comments"}
              field={content.comments}
            />
          )}
          <h2>Graduate Application</h2>
          <Table celled>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <strong>Name</strong>
                </Table.Cell>
                <Table.Cell>
                  {content.first_name}
                  <span> </span>
                  {content.last_name}
                </Table.Cell>
                <Table.Cell>
                  <strong>Phone Number</strong>
                </Table.Cell>
                <Table.Cell>{content.phoneNumber}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Date of Birth</strong>
                </Table.Cell>
                <Table.Cell>
                  {moment(content.birthDate).format('ll')}
                </Table.Cell>
                <Table.Cell>
                  <strong>Email</strong>
                </Table.Cell>
                <Table.Cell>{content.appl_email}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Gender Identity</strong>
                </Table.Cell>
                <Table.Cell>
                  {content.gender?.token.toString()
                    ? content.gender?.token.toString()
                    : null}
                </Table.Cell>
                <Table.Cell>
                  <strong>Address</strong>
                </Table.Cell>
                <Table.Cell>
                  <span>
                    {content.street2}, {content.city2}, {content.state2},{' '}
                    {content.zip2}
                  </span>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <span>
                    <strong>Are you a Veteran?</strong>{' '}
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span> {content.veteran?.token.toString()}</span>
                </Table.Cell>
                <Table.Cell>
                  <strong>Country of Citizenship </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.citizenshipCountry?.token.toString()}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <span>
                    <strong>Are you an F1 student?</strong>
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span> {content.f1student?.token.toString()}</span>
                </Table.Cell>
                <Table.Cell>
                  <strong>Country of Birth </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.birthCountry?.token.toString()}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <span>
                    <strong>Are you a U.S. Citizen?</strong>
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span> {content.citizen?.token.toString()}</span>
                </Table.Cell>
                <Table.Cell>
                  <strong>Native Language</strong>
                </Table.Cell>
                <Table.Cell>{content.native}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Status </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.temporary && <span>{content.temporary}</span>}
                </Table.Cell>
                <Table.Cell>
                  <strong>Date Obtained (Permanent) </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.permanent2 &&
                    moment(content.permanent2).format('ll')}{' '}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Date Obtained (Temporary) </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.temporary2 &&
                    moment(content.temporary2).format('ll')}{' '}
                </Table.Cell>
                <Table.Cell>
                  <strong>Date Expired (Temporary) </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.temporary3 &&
                    moment(content.temporary3).format('ll')}{' '}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Alien (A) Number </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.permanent && content.permanent}
                </Table.Cell>
                <Table.Cell>
                  <strong>Comments </strong>
                </Table.Cell>
                <Table.Cell>{content.other && content.other}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>TOEFL or IELTS score </strong>
                </Table.Cell>
                <Table.Cell>{content.toefl}</Table.Cell>
                <Table.Cell>
                  <strong>Unofficial copy of TOEFL or IELTS score </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.score && (
                    <DocumentSelector
                      type={content?.score?.filename}
                      file={content?.score}
                    />
                  )}
                  <br />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Current Resume </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.resume && (
                    <DocumentSelector
                      type={content?.resume?.filename}
                      file={content?.resume}
                    />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <strong>Present Occupation</strong>
                </Table.Cell>
                <Table.Cell>{content.occupation}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <strong>Personal Statement </strong>
                </Table.Cell>
                <Table.Cell>
                  {content.statement && (
                    <DocumentSelector
                      type={content?.statement?.filename}
                      file={content?.statement}
                    />
                  )}
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          {content?.school && (
            <p>
              <strong>School Information</strong>
            </p>
          )}
          {content?.school && <SchoolWidget school={content.school} />}

          {content?.employerAddress && (
            <p>
              <strong>Employer Information</strong>
            </p>
          )}
          {content?.employerAddress && (
            <EmployerWidget employer={content.employerAddress} />
          )}
          {content?.references && (
            <p>
              <strong>References Information</strong>
            </p>
          )}
          {content?.references && (
            <ReferencesWidget references={content.references} />
          )}
          {content.rconsent && <div>{content.rconsent.token.toString()}</div>}
          <h2> Internal Use </h2>
          <br />
          {content.transcript && (
            <AttachmentSelector
              type={'Official Transcript (Internal Use)'}
              file={content.transcript}
            />
          )}
          {content.transcript1 && (
            <AttachmentSelector
              type={'Official Transcript (Internal Use)'}
              file={content.transcript1}
            />
          )}
          {content.transcript2 && (
            <AttachmentSelector
              type={'Official Transcript (Internal Use)'}
              file={content.transcript2}
            />
          )}
          {content.transcript3 && (
            <AttachmentSelector
              type={'Official Transcript (Internal Use)'}
              file={content.transcript3}
            />
          )}
          {content.grade_equivalent && (
            <AttachmentSelector
              type={'Grade Equivalent (Internal Use)'}
              file={content.grade_equivalent}
            />
          )}
          {content.english_translation && (
            <AttachmentSelector
              type={'English Translation (Internal Use)'}
              file={content.english_translation}
            />
          )}
          <br />
          <p>Created {moment(content.created).format('ll')}</p>
          <p>Last modified {moment(content.modified).fromNow('ll')} ago</p>
        </Form>
      )}
    </Container>
  ) : (
    <Container>
      <title>Protected Resource</title>
      <h1>Protected Resource</h1>
      <p className="description">
        You are trying to access a protected resource, you are not authorized to
        view this resource.
      </p>
      <br />
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <FormattedMessage
                    id="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
                    defaultMessage="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
                    values={{
                      site_admin: (
                        <Link to="/contact-form">
                          <FormattedMessage
                            id="Site Administration"
                            defaultMessage="Site Administration"
                          />
                        </Link>
                      ),
                    }}
                  />
                  <br />
                  <FormattedMessage
                    id="Thank you."
                    defaultMessage="Thank you."
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>{' '}
          <Grid.Column textAlign="center">
            <img
              src={DocsPortalImage}
              alt={DocsPortalImage}
              style={{ width: '50%' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default ApplicationForm;

import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const DefaultLoader = (props) => (
  <div>
    <Dimmer.Dimmable as="div" blurring dimmed={props?.active}>
      <Dimmer active={props?.active} page={true} inverted>
        <Loader indeterminate size="massive">
          Loading
        </Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  </div>
);

export default DefaultLoader;

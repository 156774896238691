import PDFViews from 'volto-yc-documents/components/PDFViews/PDFViews';
import YCImageView from 'volto-yc-documents/components/YCImageView/YCImageView';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const DocumentSelector = ({ type, file }) => {
  return (
    <div>
      {type && (
        <Modal
          trigger={
            <span>
              <Link
                title={`View ${
                  (type && type?.title) ||
                  (type && type) ||
                  (type && type?.toString()) ||
                  (type && type?.token)
                }`}
                to={flattenToAppURL(file?.download)}
                onClick={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                {(type && type?.title) ||
                  (type && type) ||
                  (type && type?.toString()) ||
                  (type && type?.token)}
              </Link>
            </span>
          }
          scrolling
          closeIcon={<i className="ui close icon"></i>}
        >
          <Modal.Header>
            {(type && type?.title) ||
              (type && type) ||
              (type && type?.toString()) ||
              (type && type?.token)}
          </Modal.Header>
          <Modal.Content scrolling>
            {file ? (
              <PDFViews
                file={file?.download}
                type={file?.['content-type']}
                filename={file?.filename}
              />
            ) : (
              <YCImageView file={file?.scales?.preview?.download} />
            )}
          </Modal.Content>
        </Modal>
      )}
      <span> </span>
      <a
        href={flattenToAppURL(file?.download)}
        title="Download File"
        aria-label="Download"
        data-testid="get-file__download-button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
          height="16px"
          width="16px"
          fill="currentcolor"
          className="icon"
        >
          <g fill-rule="evenodd">
            <path
              d="M23.707 13.707L22.293 12.293 19 15.586 19 5 17 5 17 15.586 13.707 12.293 12.293 13.707 18 19.414zM9 29L27 29 27 25 9 25 9 29zM7 31L29 31 29 23 7 23 7 31z"></path>
          </g>
        </svg>
      </a>
    </div>
  );
};

export default DocumentSelector;

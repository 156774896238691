import React, { useState, useEffect } from 'react';
import { Container, Table, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { searchContent } from '@plone/volto/actions/search/search';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { Link } from 'react-router-dom';
import ViewHistoryModal from 'volto-yc-documents/components/ViewHistoryModal';

const MyDocuments = () => {
  const results = useSelector(
    (state) => state.search.subrequests.content?.items,
  );
  const dispatch = useDispatch();
  const Creator = useSelector(
    (state) => jwtDecode(state.userSession.token).sub,
  );
  const currentPage = 0;
  const pageSize = 50;
  const sort_on = 'created';
  const sort_order = 'ascending';
  const metadata_fields = [
    'CreationDate',
    'ModificationDate',
    'getPath',
    'review_state',
  ];
  const portal_type = [
    'applicationform',
    'recommendations',
    'admissions',
    'bursar',
    'finaid',
    'Financial Aid',
    'health',
    'hr',
    'Folder',
    'learning',
    'osas',
    'provost',
    'registrar',
    'relief_fund',
    'seek',
    'student_activities',
    'trio',
    'psid',
    'it',
    'cpl',
    'childcare_center',
    'finaid_docs',
    'Folder',
    'learning_docs',
    'eoc',
    'eoc_docs',
    'trio_forms',
    'osas_docs',
    'childcare',
    'Financial Aid Docs',
    'bursar_docs',
    'registrar_docs',
    'seek_docs',
    'relief_fund_docs',
    'health_docs',
    'hr_docs',
    'admissions_docs',
    'change_of_grade',
    'ot',
    'athletics',
    'csd',
    'scholarship',
    'student_activity',
    'va',
    'yeca',
  ];
  useEffect(() => {
    dispatch(
      searchContent(
        '',
        {
          b_size: pageSize,
          b_start: currentPage * pageSize,
          sort_on: sort_on,
          sort_order: sort_order,
          metadata_fields: metadata_fields,
          Creator: Creator,
          // portal_type: portal_type,
        },
        'content',
      ),
    );
  }, []);
  return (
    <Container>
      <h2>My Documents</h2>

      {results && results[0] && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Creation Date</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Review State</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {results &&
              results.map((item) => (
                <Table.Row>
                  <Table.Cell>
                    {moment(item.CreationDate).format('ll')}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={flattenToAppURL(item['@id'])}>
                      {item['@type']}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{item.review_state}</Table.Cell>
                  <Table.Cell>
                    <ViewHistoryModal item={item} />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      )}
    </Container>
  );
};

export default MyDocuments;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUser } from '@plone/volto/actions/users/users';
import jwtDecode from 'jwt-decode';

class ShowEmail extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let id = jwtDecode(this.props.token).sub;
    const EmailComponent = (id) => {
      let response = this.props.getUser(id);
      let email = response?.email;
      return <a href={`mailto:${email}`}>{email}</a>;
    };
    return (<div>
      <EmailComponent id={id} />
    </div>);
  }
}

const mapStateToProps = (state) => ({
  users: state.users.data,
});

const mapDispatchToProps = { getUser };
export default connect(mapStateToProps, mapDispatchToProps)(ShowEmail);

export const links = [
  {
    name: 'Future Student',
    link: 'https://www.york.cuny.edu/admissions',
    styles: 'cd-nav-icons',
    sublinks: [
      {
        name: 'Freshman Admissions',
        link: 'https://www.york.cuny.edu/admissions/freshman',
        styles: 'item-1',
        description: 'I have never attended college',
      },
      {
        name: 'Transfer Admissions',
        link: 'https://www.york.cuny.edu/admissions/transfer',
        styles: 'item-2',
        description: 'I studied at other post-secondary institutions',
      },
      {
        name: 'Graduate Admissions',
        link: 'https://www.york.cuny.edu/admissions/graduate',
        styles: 'item-3',
        description: 'I graduated from a post-secondary institution',
      },
      {
        name: 'International Admissions',
        link: 'https://www.york.cuny.edu/admissions/international',
        styles: 'item-4',
        description: 'I\'m not a US Resident / Citizen',
      },
      {
        name: 'Veterans Admission',
        link: 'https://www.york.cuny.edu/veterans-affairs',
        styles: 'item-5',
        description: 'I served in the US military',
      },
      {
        name: 'Other Applicants',
        link: 'https://www.york.cuny.edu/admissions/others',
        styles: 'item-6',
        description: 'Non-degree, Second Degree, Adult Learner, Senior Citizens',
      },
      {
        name: 'Admissions Team/Office Hours',
        link: 'https://www.york.cuny.edu/admissions/team',
        styles: 'item-7',
        description: 'Meet the York College Admission Team, Virtual and In-person Walk-In Hours',
      },
      {
        name: 'Campus Tour',
        link: 'https://www.york.cuny.edu/admissions/campus-tours',
        styles: 'item-8',
        description: 'Take a virtual Tour of our campus',
      },
      {
        name: 'Financial Aid',
        link: 'https://www.york.cuny.edu/financial-aid',
        styles: 'item-9',
        description: 'I need help paying for college',
      },
    ],
  },
  {
    name: 'Current Student',
    link: 'https://www.york.cuny.edu/current-student',
    styles: 'cd-secondary-nav',
    sublinks: [
      {
        name: 'Online Services',
        styles: 'has-children',
        sublink: [
          { link: 'https://www.cuny.edu/about/administration/offices/cis/technology-services/brightspace/', styles: 'menustrong', name: 'Brightspace / Blackboard' },
          { link: 'https://www.york.cuny.edu/news/2018/the-york-college-online-bookstore', name: 'Bookstore' },
          {
            link: 'https://www2.cuny.edu/about/administration/offices/registrar/resources/ePermit/',
            name: 'CUNY ePermit',
          },
          { link: 'https://home.cunyfirst.cuny.edu/', styles: 'menustrong', name: 'CUNYfirst' },
          { link: 'https://myinfo.cuny.edu/', name: 'CUNYfirst MyInfo' },
          { link: 'https://degreeworks.cuny.edu/Dashboard_yk', name: 'DegreeWorks' },
          { link: 'https://dropbox.cuny.edu/', styles: 'menustrong', name: 'Dropbox' },
          { link: 'https://www.outlook.com/owa/yorkmail.cuny.edu', styles: 'menustrong', name: 'e-mail / Office 365' },
          { link: 'https://www.york.cuny.edu/it/service-delivery-unit/y-connect', name: 'IT Self Service' },
          { link: 'https://www.york.cuny.edu/academics/navigate', name: 'Navigate' },
          { link: 'https://parking.york.cuny.edu/', name: 'Parking Permits and Violations' },
          { link: 'https://www.york.cuny.edu/stpassword/', name: 'Password Management' },
          { link: 'https://www.york.cuny.edu/registrar/schedule-builder', name: 'Schedule Builder' },
          { link: 'https://www.york.cuny.edu/tutor', styles: 'menustrong', name: 'Tutor.com' },
          { link: 'https://www.york.cuny.edu/it/network-access/vpn', styles: 'menustrong', name: 'VPN Access' },
          { link: 'https://cuny.zoom.us', name: 'Zoom' },

        ],
      },
      {
        name: 'Academics',
        link: 'https://www.york.cuny.edu/academics',
        styles: 'has-children',
        sublink: [
          { link: 'https://www.york.cuny.edu/advisement', name: 'Academic Advisement Center' },
          { link: 'https://www.york.cuny.edu/registrar/calendar', styles: 'menustrong', name: 'Academic Calendar' },
          { link: 'https://www.york.cuny.edu/academics/honors', name: 'Academic Honors' },
          {
            link: 'https://www.york.cuny.edu/academics/academic-affairs',
            name: 'Academic Affairs',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/college-now', name: 'College Now' },
              { link: 'https://www.york.cuny.edu/cuny-explorers', name: 'CUNY Explorers' },
              { link: 'https://www.york.cuny.edu/honors', name: 'Honors Program' },
              { link: 'https://www.york.cuny.edu/undergraduate-research', name: 'Undergraduate Research' },
              { link: 'https://www.york.cuny.edu/university-skills-immersion-program', name: 'University Skills and Immersion Program (USIP)' },
              { link: 'https://www.york.cuny.edu/academics/york-stem-academy', name: 'York STEM Academy' },
            ],
          },
          { link: 'https://www.york.cuny.edu/ace', name: 'Accelerate, Complete, Engage (ACE)' },
          {
            link: 'https://york-graduate.catalog.cuny.edu/',
            styles: 'menustrong',
            name: 'Bulletin - Graduate',
          },
          {
            link: 'https://york-undergraduate.catalog.cuny.edu/',
            styles: 'menustrong',
            name: 'Bulletin - Undergraduate',
          },
          { link: 'https://www.york.cuny.edu/collaborative-learning-center', name: 'Collaborative Learning Center' },
          { link: 'https://www.york.cuny.edu/commencement', name: 'Commencement', styles: 'menustrong' },
          { link: 'https://www.york.cuny.edu/registrar/course-schedule', name: 'Course Schedule' },

          { link: 'https://www.york.cuny.edu/registrar', styles: 'menustrong', name: 'Registrar' },
          {
            link: 'https://www.york.cuny.edu/arts-and-sciences',
            name: 'Arts & Sciences',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/behavioral-sciences', name: 'Behavioral Sciences' },
              { link: 'https://www.york.cuny.edu/biology', name: 'Biology' },
              { link: 'https://www.york.cuny.edu/chemistry', name: 'Chemistry' },
              { link: 'https://www.york.cuny.edu/earth-and-physical-sciences', name: 'Earth and Physical Sciences' },
              { link: 'https://www.york.cuny.edu/english', name: 'English' },
              { link: 'https://www.york.cuny.edu/history-and-philosophy', name: 'History, Philosophy, and Anthropology' },
              {
                link: 'https://www.york.cuny.edu/mathematics-and-computer-science',
                name: 'Mathematics and Computer Science',
              },
              { link: 'https://www.york.cuny.edu/performing-and-fine-arts', name: 'Performing and Fine Arts' },
              {
                link: 'https://www.york.cuny.edu/foreign-languages-esl-humanities',
                name: 'World Languages, Literatures, and Humanities',
              },
            ],
          },
          {
            link: 'https://www.york.cuny.edu/business-and-information-systems',
            name: 'Business & Information Systems',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/accounting-and-finance', name: 'Accounting and Finance' },
              { link: 'https://www.york.cuny.edu/business-economics', name: 'Business and Economics' },
              { link: 'https://www.york.cuny.edu/aviation-institute', name: 'CUNY Aviation Institute' },
            ],
          },
          {
            link: 'https://www.york.cuny.edu/health-sciences-and-professional-programs',
            name: 'Health Sciences & Professional Programs',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/health-human-performance', name: 'Health & Human Performance' },
              { link: 'https://www.york.cuny.edu/health-professions', name: 'Health Professions' },
              { link: 'https://www.york.cuny.edu/nursing', name: 'Nursing' },
              { link: 'https://www.york.cuny.edu/occupational-therapy', name: 'Occupational Therapy' },
              { link: 'https://www.york.cuny.edu/social-work', name: 'Social Work' },
              { link: 'https://www.york.cuny.edu/teacher-education', name: 'Teacher Education' },
            ],
          },
          { link: 'https://www.york.cuny.edu/wac', name: 'Writing Across the Curriculum' },

        ],
      },
      {
        name: 'Student Affairs',
        link: 'https://www.york.cuny.edu/student-development',
        styles: 'has-children',
        sublink: [
          { link: 'https://www.york.cuny.edu/student-development', name: 'Student Affairs and Enrollment Management' },
          { link: 'http://www.yorkathletics.com/', name: 'Athletics' },
          { link: 'https://www.york.cuny.edu/career-services', name: 'Career Services' },

          { link: 'https://www.york.cuny.edu/child-and-family-center', name: 'Child and Family Center' },

          { link: 'https://www.york.cuny.edu/student-development/fye', name: 'First-Year Experience (FYE)' },

          { link: 'https://www.york.cuny.edu/mens-center', name: 'Men\'s Center' },
          { link: 'https://www.york.cuny.edu/student-development/mentoring', name: 'Mentoring' },
          {
            link: 'https://www.york.cuny.edu/enrollment-mngmt-office',
            name: 'Office of Enrollment Management',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/admissions', name: 'Admissions' },
              { link: 'https://www.york.cuny.edu/financial-aid', name: 'Financial Aid' },
              { link: 'https://www.york.cuny.edu/veterans-affairs', name: 'Veteran\'s Affairs' },
              { link: 'https://www.york.cuny.edu/welcome-center', name: 'Welcome Center' },
            ],
          },
          { link: 'https://www.york.cuny.edu/ombudsperson', name: 'Office of Ombudsperson' },
          { link: 'https://www.york.cuny.edu/seek', name: 'Percy E. Sutton SEEK program' },
          {
            link: 'https://www.york.cuny.edu/student-development/student-activities',
            name: 'Student Activities',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/student-development/jumpstart', name: 'JumpStart' },
              {
                link: 'https://www.york.cuny.edu/student-development/student-activities/student-clubs-and-organization/the-national-society-of-leadership-and-success',
                name: 'National Society of Leadership and Success',
              },
              {
                link: 'https://www.york.cuny.edu/student-development/student-activities/student-government',
                name: 'Student Government Association',
              },
              { link: 'https://www.york.cuny.edu/ycradio', name: 'YCRadio' },
            ],
          },
          {
            link: 'https://www.york.cuny.edu/cares',
            name: 'Wellness and Resources',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/counseling-center', name: 'Counseling Center' },
              { link: 'https://www.york.cuny.edu/csd', name: 'Center for Students with Disabilities' },
              { link: 'https://www.york.cuny.edu/health', name: 'Health Services' },
              { link: 'https://www.york.cuny.edu/womens-center', name: 'Women\'s Center for Gender Justice' },
            ],
          },

        ],
      },
      {
        name: 'Student Resources',
        link: 'https://www.york.cuny.edu/current-student',
        styles: 'has-children',
        sublink: [
          { link: 'https://www.york.cuny.edu/student-development/bit', name: 'Behavioral Intervention Team (BIT)' },
          { link: 'https://www.york.cuny.edu/events', name: 'Calendar' },
          { link: 'https://www.york.cuny.edu/cares', name: 'CARES Act Services' },
          {
            link: 'https://www.york.cuny.edu/ctlet',
            name: 'Center for Teaching, Learning and Educational Technologies (CTLET)',
          },
          { link: 'https://www.york.cuny.edu/about/consumer-information', name: 'Consumer Information' },
          { link: 'https://www.york.cuny.edu/directory', name: 'Directory' },
          { link: 'https://www.york.cuny.edu/financial-aid', styles: 'menustrong', name: 'Financial Aid Office' },
          { link: 'https://www.york.cuny.edu/library', name: 'Library' },
          { link: 'https://www.york.cuny.edu/ctlet/lynda', name: 'LinkedIn Learning' },
          { link: 'https://www.york.cuny.edu/bursar', styles: 'menustrong', name: 'Office of the Bursar' },
          { link: 'https://www.york.cuny.edu/public-safety', name: 'Public Safety' },
          { link: 'https://www.york.cuny.edu/senate', styles: 'menustrong', name: 'Senate' },
          { link: 'https://www.york.cuny.edu/scholarship', styles: 'menustrong', name: 'Scholarships Center' },
          { link: 'https://www.york.cuny.edu/study-abroad', name: 'Study Abroad' },
          { link: 'https://www.york.cuny.edu/pac', name: 'The Milton G. Bassin Performing Arts Center' },
          { link: 'https://www.york.cuny.edu/welcome-center', styles: 'menustrong', name: 'Welcome Center' },
        ],
      },
    ],
  },
  {
    name: 'Faculty / Staff',
    link: 'https://www.york.cuny.edu/faculty-staff',
    styles: 'cd-secondary-nav',
    sublinks: [
      {
        name: 'Online Services',
        styles: 'has-children',
        sublink: [
          {
            link: 'https://www.york.cuny.edu/human-resources/current-employee',
            styles: 'menustrong',
            name: 'AEMS | HR/PR Assist',
          },
          {
            link: 'https://www.york.cuny.edu/administrative/office-of-facilities-and-planning/bg/archibus',
            name: 'B&G Work Order',
          },
          { link: 'https://www.york.cuny.edu/news/2018/the-york-college-online-bookstore', name: 'Bookstore' },
          { link: 'https://www.cuny.edu/about/administration/offices/cis/technology-services/brightspace/', styles: 'menustrong', name: 'Brightspace / Blackboard' },
          { link: 'https://solutions.sciquest.com/apps/Router/SAMLAuth/CUNY', styles: '_blank', name: 'CUNYBuy' },
          { link: 'https://home.cunyfirst.cuny.edu/', styles: '_blank', name: 'CUNYFirst' },
          { link: 'https://degreeworks.cuny.edu/', name: 'DegreeWorks' },
          { link: 'https://dropbox.cuny.edu/', name: 'Dropbox' },
          {
            link: 'https://login.microsoftonline.com/?whr=login.cuny.edu',
            styles: 'menustrong',
            name: 'e-mail / Office 365',
          },
          { link: 'https://www.york.cuny.edu/it/service-delivery-unit/y-connect', name: 'IT Self Service' },
          { link: 'https://www.york.cuny.edu/academics/navigate', name: 'Navigate' },
          { link: 'https://parking.york.cuny.edu', name: 'Parking Permits and Violations' },
          { link: 'https://www.york.cuny.edu/it/service-delivery-unit/qualtrics', name: 'Qualtrics' },
          { link: 'https://www.york.cuny.edu/it/network-access/vpn', styles: 'menustrong', name: 'VPN Access' },
          { link: 'https://www.york.cuny.edu/login?return_url=/dashboard', name: 'York Website/CMS' },
          { link: 'https://cuny.zoom.us', styles: 'menustrong', name: 'Zoom' },

        ],
      },
      {
        name: 'Academics',
        link: 'https://www.york.cuny.edu/academics/academic-affairs',
        styles: 'has-children',
        sublink: [
          { link: 'https://www.york.cuny.edu/academic-affairs', name: 'Academic Affairs' },
          { link: 'https://www.york.cuny.edu/advisement', name: 'Academic Advisement Center' },
          { link: 'https://www.york.cuny.edu/registrar/calendar', styles: 'menustrong', name: 'Academic Calendar' },
          {
            link: 'https://www.york.cuny.edu/academics/academic-affairs/newsletter',
            name: 'Academic Affairs Newsletters',
          },
          {
            link: 'https://york-graduate.catalog.cuny.edu/',
            styles: 'menustrong',
            name: 'Bulletin - Graduate',
          },

          {
            link: 'https://york-undergraduate.catalog.cuny.edu/',
            styles: 'menustrong',
            name: 'Bulletin - Undergraduate',
          },
          {
            link: 'https://www.york.cuny.edu/academics/academic-affairs/faculty-development',
            name: 'Faculty Development',
          },
          { link: 'https://www.york.cuny.edu/academics/academic-affairs/faculty-resources', name: 'Faculty Resources' },
          { link: 'https://www.york.cuny.edu/academics/academic-affairs/pathways', name: 'General Education' },
          { link: 'https://www.york.cuny.edu/registrar', name: 'Registrar' },
          { link: 'https://www.york.cuny.edu/orsp', name: 'Research and Sponsored Program' },
          {
            link: 'https://www.york.cuny.edu/arts-and-sciences',
            name: 'Arts & Sciences',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/behavioral-sciences', name: 'Behavioral Sciences' },
              { link: 'https://www.york.cuny.edu/biology', name: 'Biology' },
              { link: 'https://www.york.cuny.edu/chemistry', name: 'Chemistry' },
              { link: 'https://www.york.cuny.edu/earth-and-physical-sciences', name: 'Earth and Physical Sciences' },
              { link: 'https://www.york.cuny.edu/english', name: 'English' },
              { link: 'https://www.york.cuny.edu/history-and-philosophy', name: 'History, Philosophy, and Anthropology' },
              {
                link: 'https://www.york.cuny.edu/mathematics-and-computer-science',
                name: 'Mathematics and Computer Science',
              },
              { link: 'https://www.york.cuny.edu/performing-and-fine-arts', name: 'Performing and Fine Arts' },
              {
                link: 'https://www.york.cuny.edu/foreign-languages-esl-humanities',
                name: 'World Languages, Literatures, and Humanities',
              },
            ],
          },
          {
            link: 'https://www.york.cuny.edu/business-and-information-systems',
            name: 'Business & Information Systems',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/accounting-and-finance', name: 'Accounting and Finance' },
              { link: 'https://www.york.cuny.edu/business-economics', name: 'Business and Economics' },
              { link: 'https://www.york.cuny.edu/aviation-institute', name: 'CUNY Aviation Institute' },
            ],
          },
          {
            link: 'https://www.york.cuny.edu/health-sciences-and-professional-programs',
            name: 'Health Sciences & Professional Programs',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/health-human-performance', name: 'Health & Human Performance' },
              { link: 'https://www.york.cuny.edu/health-professions', name: 'Health Professions' },
              { link: 'https://www.york.cuny.edu/nursing', name: 'Nursing' },
              { link: 'https://www.york.cuny.edu/occupational-therapy', name: 'Occupational Therapy' },
              { link: 'https://www.york.cuny.edu/social-work', name: 'Social Work' },
              { link: 'https://www.york.cuny.edu/teacher-education', name: 'Teacher Education' },
            ],
          },
          { link: 'https://www.york.cuny.edu/undergraduate-research', name: 'Undergraduate Research' },
        ],
      },
      {
        name: 'Administration',
        link: 'https://www.york.cuny.edu/administrative',
        styles: 'has-children',
        sublink: [
          {
            link: 'https://www.york.cuny.edu/president',
            name: 'Office of the President',
            styles: 'has-children',
            sublink: [
              {
                link: 'https://www.york.cuny.edu/government-relations-and-strategic-initiatives',
                name: 'Government Relations and Strategic Initiatives',
              },
              {
                link: 'https://www.york.cuny.edu/institutional-effectiveness',
                name: 'Institutional Effectiveness and Strategic Planning',
              },
              { link: 'https://www.york.cuny.edu/diversity-and-compliance', name: 'Diversity and Compliance' },
              { link: 'https://www.york.cuny.edu/legal', name: 'Legal Affairs and Labor Relations' }],
          },
          {
            link: 'https://www.york.cuny.edu/administrative/office-of-facilities-and-planning',
            name: 'Facilities and Planning',
            styles: 'has-children',
            sublink: [
              {
                link: 'https://www.york.cuny.edu/administrative/office-of-facilities-and-planning/bg',
                name: 'Building and Grounds',
              },
              {
                link: 'https://www.york.cuny.edu/administrative/office-of-facilities-and-planning/environmental',
                name: 'Environment Health and Safety',
              },
              { link: 'https://www.york.cuny.edu/pac', name: 'Performing Arts Center' },
              {
                link: 'https://www.york.cuny.edu/administrative/office-of-facilities-and-planning/special-events-office',
                name: 'Special Events Office',
              }],
          },
          {
            link: 'https://www.york.cuny.edu/administrative/business-office',
            name: 'Financial Planning and Budget',
            styles: 'has-children',
            sublink: [
              {
                link: 'https://www.york.cuny.edu/administrative/financial-planning-and-budget/budget-office',
                name: 'Budget Office',
              },
              { link: 'https://www.york.cuny.edu/bursar', name: 'Bursar Office' },
              {
                link: 'https://www.york.cuny.edu/administrative/financial-planning-and-budget/business-office',
                name: 'Business Office',
              },
              {
                link: 'https://www.york.cuny.edu/administrative/financial-planning-and-budget/purchasing',
                name: 'Purchasing',
              },
            ],
          },
          {
            link: 'https://www.york.cuny.edu/president/government-and-community-relations',
            name: 'Government Relations and Strategic Initiatives',
            styles: 'has-children',
            sublink: [
              {
                link: 'https://www.york.cuny.edu/conted',
                name: 'Continuing and Professional Education Center',
              },
              {
                link: 'https://www.york.cuny.edu/learning-center',
                name: 'York College Learning Center',
              },
              {
                link: 'https://www.york.cuny.edu/sbdc',
                name: 'Small Business Development Center',
              },
              {
                link: 'https://www.york.cuny.edu/start-up-ny',
                name: 'START-UP-NY',
              }],
          },
          {
            link: 'https://www.york.cuny.edu/human-resources',
            styles: 'menustrong',
            name: 'Human Resources',
          },
          {
            link: 'https://www.york.cuny.edu/it',
            name: 'Information Technology',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/it/network-access', name: 'Infrastructure' },
              { link: 'https://www.york.cuny.edu/it/service-delivery-unit', name: 'Service Delivery' },
              { link: 'https://www.york.cuny.edu/webteam', name: 'Web and Business Intelligence' },
            ],
          },
          {
            link: 'https://www.york.cuny.edu/ia',
            name: 'Office of Institutional Advancement',
            styles: 'has-children',
            sublink: [
              { link: 'https://www.york.cuny.edu/ia/dev-alum', name: 'Alumni Affairs' },
              { link: 'https://www.york.cuny.edu/ia/annual-fund', name: 'Annual Fund' },
              { link: 'https://www.york.cuny.edu/ia/donor-relations', name: 'Donor Relations' },
              { link: 'https://www.york.cuny.edu/ia/giving', name: 'Give to York' },
              { link: 'https://www.york.cuny.edu/ia/support-scholarships', name: 'Support Scholarships' },
              { link: 'https://www.york.cuny.edu/ia/foundation-board', name: 'York College Foundation' },
            ],
          },
          {
            link: 'https://www.york.cuny.edu/institutional-effectiveness',
            name: 'Office of Institutional Effectiveness',
          },
          { link: 'https://www.york.cuny.edu/public-safety', styles: 'menustrong', name: 'Public Safety' },
        ],
      },
      {
        name: 'Resources',
        styles: 'has-children',
        sublink: [
          { link: 'https://www.york.cuny.edu/student-development/bit', name: 'Behavioral Intervention Team (BIT)' },
          { link: 'https://www.york.cuny.edu/events', name: 'Calendar' },
          { link: 'https://www.york.cuny.edu/cares', name: 'CARES Act Services' },
          {
            link: 'https://www.york.cuny.edu/ctlet',
            name: 'Center for Teaching, Learning and Educational    Technologies (CTLET)',
          },
          {
            link: 'https://bbhosted.cuny.edu/webapps/blackboard/execute/announcement?method=search&context=course_entry&course_id=_2323641_1&handle=announcements_entry&mode=view',
            styles: 'menustrong',
            name: 'Cybersecurity Awareness Training',
          },
          { link: 'https://www.york.cuny.edu/directory', styles: 'menustrong', name: 'Directory' },
          { link: 'https://www.york.cuny.edu/library', styles: 'menustrong', name: 'Library' },
          { link: 'https://www.york.cuny.edu/ctlet/lynda', name: 'LinkedIn Learning' },
          {
            link: 'https://www.york.cuny.edu/marketing',
            name: 'Marketing and Communications',
          },
          { link: 'https://www.york.cuny.edu/bursar', name: 'Office of the Bursar' },
          { link: 'https://www.york.cuny.edu/administrative/printing-services', name: 'Printing Services' },
          { link: 'https://www.york.cuny.edu/senate', styles: 'menustrong', name: 'Senate' },
          { link: 'https://www.york.cuny.edu/pac', name: 'The Milton G. Bassin Performing Arts Center' },
          { link: 'https://www.york.cuny.edu/wac', name: 'Writing Across the Curriculum' },
          { link: 'https://www.york.cuny.edu/yams', name: 'York Assessment Management System (YAMS)' },
        ],
      },
    ],
  },
  {
    name: 'Alumni / Friends',
    link: 'https://www.york.cuny.edu/alumni',
    styles: 'cd-nav-gallery',
    sublinks: [
      {
        name: 'Institutional Advancement',
        link: 'https://www.york.cuny.edu/ia',
        image: 'https://archives.york.cuny.edu/static/yorklook5/img/friends-development.jpg',
      },
      {
        name: 'YC Foundation, Inc.',
        link: 'https://www.york.cuny.edu/ia/foundation-board',
        image: 'https://archives.york.cuny.edu/static/yorklook5/img/friends-ycf.png',
      },
      {
        name: 'Alumni Association, Inc.',
        link: 'https://www.york.cuny.edu/alumni/association',
        image: 'https://archives.york.cuny.edu/static/yorklook5/img/friends-alumni.jpg',
      },
      {
        name: 'Alumni Relations',
        link: 'https://www.york.cuny.edu/ia/devalum',
        image: 'https://archives.york.cuny.edu/static/yorklook5/img/alumni-resources.jpg',
      },
    ],
  },
];

import { ITEM_COUNT } from 'volto-yc-documents/constants/ActionTypes';

export default function item_count(url, state) {
  return {
    type: ITEM_COUNT,
    payload: state,
    request: {
      op: 'get',
      path: `${url}/@item_count`,
    },
  };
}

import React from 'react';
import { Container } from 'semantic-ui-react';

import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
// For loading images!
import YCImageView from 'volto-yc-documents/components/YCImageView/YCImageView';
// // Core viewer
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
// // Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// // Styling!
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViews = ({ file, type }) => {
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Container>
      {type.startsWith('image') && <YCImageView file={flattenToAppURL(file)} />}{' '}
      {type.startsWith('application/pdf') && (
        <Worker workerUrl={flattenToAppURL('/static/pdfworkermin.js')}>
          <Viewer
            fileUrl={flattenToAppURL(file)}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>
      )}
      <br />
    </Container>
  );
};

export default PDFViews;

import moment from 'moment';
import React from 'react';

const VoltoDateTime = ({ label, field }) => {
  if (label && field) {
    return (
      <span>
        <h2>
          {field && label}
          <br />
        </h2>
        {field && <p>{moment(field).format('ll')}</p>}
        <br></br>
      </span>
    );
  }
  return <p>{field && moment(field).format('ll')}</p>;
};

export default VoltoDateTime;

import React from 'react';
import { Image } from 'semantic-ui-react';

const YCImageView = ({ file }) => {
  return (
    <div>
      <Image src={file} alt=" " />
    </div>
  );
};

export default YCImageView;

import moment from 'moment';
import { Container, Form } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import PDFViews from 'volto-yc-documents/components/PDFViews/PDFViews';
import YCImageView from 'volto-yc-documents/components/YCImageView/YCImageView';
import Helmet from '@plone/volto/helpers/Helmet/Helmet';
import DefaultLoader from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/DefaultLoader';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '@plone/volto/actions/users/users';
import jwtDecode from 'jwt-decode';

const ChildcareCenterDocList = (props) => {
  const { content, token } = props;
  const id = jwtDecode(token).sub;
  const dispatch = useDispatch();
  const ShowUserEmail = (id) => {
    let response = dispatch(getUser(id));
    let email = response?.email;
    console.log('email: ', email);
    return email;
  };
  const AttachmentSelector = ({ type, file }) => {
    return (
      <div>
        {content?.preview === 'True' ? (
          <div>
            <p>
              <strong> Attachment: </strong>
              {(type && type?.title) ||
                (type && type) ||
                (type && type?.toString()) ||
                (type && type?.token)}{' '}
              - <a href={file?.download}>{file?.filename}</a>
            </p>
            {file ? (
              <PDFViews
                file={file?.download}
                type={file?.['content-type']}
                filename={file?.filename}
              />
            ) : (
              <YCImageView file={file?.scales?.preview?.download} />
            )}{' '}
          </div>
        ) : (
          <p>
            <strong> Attachment: </strong>
            {(type && type?.title) ||
              (type && type) ||
              (type && type?.toString()) ||
              (type && type?.token)}{' '}
            - {file?.filename}
          </p>
        )}
      </div>
    );
  };

  const FieldSelector = ({ label, field }) => {
    if (label && field) {
      return (
        <span>
          <strong>{field && label}: </strong>
          {field && field}
          <br />
        </span>
      );
    }
    return <span>{field && field}</span>;
  };
  const description =
    content?.last_name +
    ', ' +
    content?.first_name +
    ' - ' +
    content?.employeeID;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Container>
      {!loaded ? (
        <DefaultLoader active={!loaded} />
      ) : (
        <Form>
          <Helmet title={description} />
          <h1 className="documentFirstHeading">{description}</h1>
          {token && (
            <FieldSelector label="Email" field={email(token)} />
          )}
          {content?.ss_ && (
            <FieldSelector
              label="Last 4 Digits of Social Security Number"
              field={content?.ss_}
            />
          )}
          {content?.street_number && (
            <FieldSelector
              label="Street Number"
              field={content?.street_number}
            />
          )}
          {content?.apt___ && (
            <FieldSelector label="Apt. #" field={content?.apt___} />
          )}
          {content?.city && (
            <FieldSelector label="City" field={content?.city} />
          )}
          {content?.state && (
            <FieldSelector label="State" field={content?.state} />
          )}
          {content?.zip_code && (
            <FieldSelector label="Zip Code" field={content?.zip_code} />
          )}
          {content?.telephone && (
            <FieldSelector label="Telephone" field={content?.telephone} />
          )}
          {content?.email && (
            <FieldSelector label="Email" field={content?.email} />
          )}
          {content?.affiliation?.title && (
            <FieldSelector
              label="Affiliation"
              field={content?.affiliation?.title}
            />
          )}
          {content?.occupation && (
            <FieldSelector label="Occupation" field={content?.occupation} />
          )}
          {content?.major && (
            <FieldSelector label="Major" field={content?.major} />
          )}
          {content?.class_schedule && (
            <AttachmentSelector
              type="Class/Work Schedule"
              file={content?.class_schedule}
              filename={content?.class_schedule}
            />
          )}
          {content?.federal_work_study?.toString() && (
            <FieldSelector
              label="Federal Work Study"
              field={content?.federal_work_study?.toString()}
            />
          )}
          {content?.federal_work_study_2 && (
            <FieldSelector
              label="Federal Work Study - Location"
              field={content?.federal_work_study_2}
            />
          )}
          {content?.proof_of_income && (
            <AttachmentSelector
              type="Proof of Income"
              file={content?.proof_of_income}
              filename={content?.proof_of_income}
            />
          )}
          {content?.name && (
            <FieldSelector label="Name" field={content?.name} />
          )}
          {content?.telephone__ && (
            <FieldSelector label="Telephone #" field={content?.telephone__} />
          )}
          {content?.emergency_contact_email && (
            <FieldSelector
              label="Email"
              field={content?.emergency_contact_email}
            />
          )}
          {content?.relationship_to_the_child && (
            <FieldSelector
              label="Relationship to the Child"
              field={content?.relationship_to_the_child}
            />
          )}
          {content?.first_name2 && (
            <FieldSelector label="First Name" field={content?.first_name2} />
          )}
          {content?.last_name2 && (
            <FieldSelector label="Last Name" field={content?.last_name2} />
          )}
          {content?.middle_initial2 && (
            <FieldSelector
              label="Middle Initial"
              field={content?.middle_initial2}
            />
          )}
          {content?.child_s_age && (
            <FieldSelector label="Child's Age" field={content?.child_s_age} />
          )}
          {content?.gender?.title && (
            <FieldSelector label="Gender" field={content?.gender?.title} />
          )}
          {content?.child_dob && (
            <FieldSelector
              label="Date of Birth"
              field={moment(content?.child_dob).format('ll')}
            />
          )}
          {content?.allergies?.toString() && (
            <FieldSelector
              label="Does your child have any allergies?"
              field={content?.allergies?.toString()}
            />
          )}
          {content?.does_your_child_require_any_medication_?.toString() && (
            <FieldSelector
              label="Does your child require any medication?"
              field={content?.does_your_child_require_any_medication_?.toString()}
            />
          )}
          {content?.does_your_child_have_any_food_restrictions_?.toString() && (
            <FieldSelector
              label="Does your child have any food restrictions?"
              field={content?.does_your_child_have_any_food_restrictions_?.toString()}
            />
          )}
          {content?.allergies_or_medications && (
            <FieldSelector
              label="Allergies, Medications or Food Restrictions"
              field={content?.allergies_or_medications}
            />
          )}
          {content?.do_you_have_any_additional_comments_you_would_like_to_share_about_your_child_s_health_ && (
            <FieldSelector
              label="Do you have any additional comments you would like to share about
            your child's health?"
              field={
                content?.do_you_have_any_additional_comments_you_would_like_to_share_about_your_child_s_health_
              }
            />
          )}
          {content?.medical_form && (
            <AttachmentSelector
              type="Medical Form"
              file={content?.medical_form}
              filename={content?.medical_form}
            />
          )}
          {content?.immunization_record && (
            <AttachmentSelector
              type="Immunization Record"
              file={content?.immunization_record}
              filename={content?.immunication_record}
            />
          )}
          {content?.birth_certificate && (
            <AttachmentSelector
              type="Birth Certificate"
              file={content?.birth_certificate}
              filename={content?.birth_certificate}
            />
          )}
          {content?.c2_first_name2 && (
            <FieldSelector label="First Name" field={content?.c2_first_name2} />
          )}
          {content?.c2_last_name2 && (
            <FieldSelector label="Last Name" field={content?.c2_last_name2} />
          )}
          {content?.c2_middle_initial2 && (
            <FieldSelector
              label="Middle Initial"
              field={content?.c2_middle_initial2}
            />
          )}
          {content?.c2_child_s_age && (
            <FieldSelector
              label="Child's Age"
              field={content?.c2_child_s_age}
            />
          )}
          {content?.c2_gender?.title && (
            <FieldSelector label="Gender" field={content?.c2_gender?.title} />
          )}
          {content?.c2_child_dob && (
            <FieldSelector
              label="Date of Birth"
              field={moment(content?.c2_child_dob).format('ll')}
            />
          )}
          {content?.c2_allergies?.toString() && (
            <FieldSelector
              label="Does your child have any allergies?"
              field={content?.c2_allergies?.toString()}
            />
          )}
          {content?.c2_does_your_child_require_any_medication_?.toString() && (
            <FieldSelector
              label="Does your child require any medication?"
              field={content?.c2_does_your_child_require_any_medication_?.toString()}
            />
          )}
          {content?.c2_does_your_child_have_any_food_restrictions_?.toString() && (
            <FieldSelector
              label="Does your child have any food restrictions?"
              field={content?.c2_does_your_child_have_any_food_restrictions_?.toString()}
            />
          )}

          {content?.c2_allergies_or_medications && (
            <FieldSelector
              label="Allergies, Medications or Food Restrictions"
              field={content?.c2_allergies_or_medications}
            />
          )}

          {content?.c2_do_you_have_any_additional_comments_you_would_like_to_share_about_your_child_s_health_ && (
            <FieldSelector
              label="Do you have any additional comments you would like to share about
            your child's health?"
              field={
                content?.c2_do_you_have_any_additional_comments_you_would_like_to_share_about_your_child_s_health_
              }
            />
          )}
          {content?.c2_medical_form && (
            <AttachmentSelector
              type="Medical Form"
              file={content?.c2_medical_form}
              filename={content?.c2_medical_form}
            />
          )}
          {content?.c2_immunization_record && (
            <AttachmentSelector
              type="Immunization Record"
              file={content?.c2_immunization_record}
              filename={content?.c2_immunization_record}
            />
          )}
          {content?.c2_birth_certificate && (
            <AttachmentSelector
              type="Birth Certificate"
              file={content?.c2_birth_certificate}
              filename={content?.c2_birth_certificate}
            />
          )}
          {content?.previous_experience_outside_home && (
            <FieldSelector
              label="Previous Experience Outside Home"
              field={content?.previous_experience_outside_home}
            />
          )}
          {content?.public_private_school && (
            <FieldSelector
              label="Public/Private School"
              field={content?.public_private_school}
            />
          )}

          {content?.family_day_care && (
            <FieldSelector
              label="Family Day Care"
              field={content?.family_day_care}
            />
          )}

          <br />
          <br />
          <p>Created {moment(content?.created).format('ll')}</p>
          <p>Last modified {moment(content?.modified).fromNow('ll')} ago</p>
        </Form>
      )}
    </Container>
  );
};

export default ChildcareCenterDocList;

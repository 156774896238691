// import {
//   EmployerWidget,
//   ReferencesWidget,
//   SchoolWidget,
// } from 'volto-yc-graduateapplication/components/Widgets/index';
import EmployerWidget from 'volto-yc-graduateapplication/components/Widgets/EmployerWidget';
import ReferencesWidget from 'volto-yc-graduateapplication/components/Widgets/ReferencesWidget';
import SchoolWidget from 'volto-yc-graduateapplication/components/Widgets/SchoolWidget';
import ApplicationForm from 'volto-yc-graduateapplication/components/ContentTypesViews/ApplicationForm';
import Recommendations from 'volto-yc-graduateapplication/components/ContentTypesViews/Recommendations';
import Unauthorized from 'volto-yc-graduateapplication/components/Unauthorized';
import raw from 'volto-yc-graduateapplication/reducers/raw/raw';
import value from 'volto-yc-graduateapplication/reducers/value/value';
// import RadioWidget from './components/Widgets/RadioWidget';
// import RadioFieldWidget from './components/Widgets/RadioFieldWidget';
export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config.addonReducers.raw = raw;
  config.addonReducers.value = value;
  config.widgets.id.employerAddress = EmployerWidget;
  config.widgets.id.school = SchoolWidget;
  config.widgets.id.references = ReferencesWidget;
  config.widgets.widget.employer_widget = EmployerWidget;
  config.widgets.widget.school_widget = SchoolWidget;
  config.widgets.widget.references_widget = ReferencesWidget;
  config.views.contentTypesViews.applicationform = ApplicationForm;
  config.views.contentTypesViews.recommendations = Recommendations;
  config.views.errorViews = {
    ...config.views.errorViews,
    401: Unauthorized,
  };
  // config.widgets.widget.single_choice = RadioWidget;
  // config.widgets.id.program = RadioFieldWidget;
  // config.widgets.id.gender = RadioFieldWidget;
  // config.widgets.id.veteran = RadioFieldWidget;
  // config.widgets.id.f1student = RadioFieldWidget;
  // config.widgets.id.citizen = RadioFieldWidget;
  // config.widgets.id.rconsent = RadioFieldWidget;
  // config.widgets.id.statementConsent = RadioFieldWidget;
  // config.widgets.id.adapt = RadioFieldWidget;
  // config.widgets.id.awareness = RadioFieldWidget;
  // config.widgets.id.maturity = RadioFieldWidget;
  // config.widgets.id.openness = RadioFieldWidget;
  // config.widgets.id.interpersonal = RadioFieldWidget;
  // config.widgets.id.confidence = RadioFieldWidget;
  // config.widgets.id.initiative = RadioFieldWidget;
  // config.widgets.id.collaboration = RadioFieldWidget;
  // config.widgets.id.thinking = RadioFieldWidget;
  // config.widgets.id.curiosity = RadioFieldWidget;
  // config.widgets.id.problemSolvingSkills = RadioFieldWidget;
  // config.widgets.id.acceptanceAR = RadioFieldWidget;
  // config.widgets.id.acceptanceNR = RadioFieldWidget;
  // config.widgets.id.signature = RadioFieldWidget;
  return config;
}

import PDFViews from 'volto-yc-documents/components/PDFViews/PDFViews';
import YCImageView from 'volto-yc-documents/components/YCImageView/YCImageView';
import React from 'react';

const AttachmentSelector = ({ type, file }) => {
  return (
    <div>
      <h2>{(type && type?.title) || (type && type?.token) || (type && type)}</h2>
      <div>
        {file ? (
          <PDFViews
            type={file?.['content-type']}
            file={file?.download}
            filename={file?.filename}
          />
        ) : (
          <YCImageView file={file?.scales?.preview?.download} />
        )}
      </div>
    </div>
  );
};

export default AttachmentSelector;

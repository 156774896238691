import React from 'react';
import DataGridFieldWidget from 'volto-yc-datagridfield/Widgets/DataGridFieldWidget';

const ItemSchema = {
  title: 'School',
  description: '(College/University/Graduate)',
  properties: {
    school: {
      title: 'School',
      type: 'string',
      factory: 'Text line (String)',
    },
    location: {
      title: 'Location',
      type: 'string',
      factory: 'Text line (String)',
    },
    year: {
      title: 'Year of Graduation',
      type: 'string',
      factory: 'Text line (String)',
    },
    gpa: {
      title: 'GPA',
      type: 'string',
      factory: 'Text line (String)',
    },
    major: {
      title: 'Major',
      type: 'string',
      factory: 'Text line (String)',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'School',
      fields: ['school', 'location', 'year', 'gpa', 'major'],
    },
  ],
  required: [],
};

const SchoolWidget = (props) => {
  return (
    <DataGridFieldWidget
      schema={ItemSchema}
      {...props}
      value={(props.value || props.default) || (props.value?.items || props.default?.items) || []}
      onChange={((id, value) => props.onChange(id, value)) || ((id, value) => props.onChange(id, {items: value}))}
    />
  );
};

export default SchoolWidget;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Segment,
  Table,
  Button,
  Divider,
  Label,
  List,
  Message,
} from 'semantic-ui-react';

import { getVotes, vote, clearVotes } from '../../actions';

const Voting = () => {
  const votes = useSelector((store) => store.votes);
  const dispatch = useDispatch();
  let location = useLocation();
  const [stateClearVotes, setStateClearVotes] = React.useState(0);
  const handleVoteClick = (value) => {
    if (location) {
      dispatch(vote(location.pathname, value));
      setStateClearVotes(0);
    }
  };
  const handleClearVotes = () => {
    if (location && stateClearVotes === 1) {
      dispatch(clearVotes(location.pathname));
    }
    let counter = stateClearVotes < 2 ? stateClearVotes + 1 : 2;
    setStateClearVotes(counter);
  };
  React.useEffect(() => {
    if (location) {
      dispatch(getVotes(location.pathname));
    }
  }, [dispatch, location]);
  return votes?.loaded && votes?.can_vote && !votes?.error ? (
    <Segment className="voting">
      <Message info>
        <List>
          <p>
            <Label.Group>
              {votes?.has_votes ? (
                <Label color="olive">
                  Average vote: <strong>{votes.average_vote}</strong>
                  <Label.Detail>
                    Total Votes Cast: {votes?.total_votes}
                  </Label.Detail>
                </Label>
              ) : (
                <b> Nobody has voted yet. </b>
              )}
            </Label.Group>
          </p>

          {votes?.can_vote ? (
            <Divider horizontal section>
              Vote
            </Divider>
          ) : null}

          {votes?.already_voted ? (
            <List.Item>
              <List.Content>
                <List.Header>You already voted.</List.Header>
              </List.Content>
            </List.Item>
          ) : votes?.can_vote ? (
            <List.Item>
              Please vote:
              <Button.Group widths="3">
                <Button color="green" onClick={() => handleVoteClick(1)}>
                  +1
                </Button>{' '}
                <Button color="orange" onClick={() => handleVoteClick(0)}>
                  0
                </Button>
                <Button color="red" onClick={() => handleVoteClick(-1)}>
                  -1
                </Button>
              </Button.Group>
            </List.Item>
          ) : null}
          {votes?.can_clear_votes && votes?.has_votes ? (
            <>
              <Divider horizontal section color="red">
                Delete all votes
              </Divider>
              <List.Item>
                <Button.Group widths="2">
                  <Button color="red" onClick={handleClearVotes}>
                    {['Delete all votes', 'Confirm Delete'][stateClearVotes]}
                  </Button>
                </Button.Group>
              </List.Item>
            </>
          ) : null}
          {votes?.can_vote ? (
            <div>
              <Table className="listing">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Voted By</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{votes?.has_voted}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          ) : null}
        </List>
      </Message>
    </Segment>
  ) : null;
};
export default Voting;

import { Pagination, Table, Ref } from 'semantic-ui-react';
import { usePagination, useTable } from 'react-table';
import { Icon } from '@plone/volto/components';
import React from 'react';
import deleteSVG from '@plone/volto/icons/delete.svg';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import plusSVG from '@plone/volto/icons/circle-plus.svg';
import { EditableCell } from './EditableCell';
import dragSVG from '@plone/volto/icons/drag.svg';

const getPlaceholder = async (draggedDOM, sourceIndex, destinationIndex) => {
  // Because of the margin rendering rules, there is no easy
  // way to calculate the offset of the placeholder.
  //
  // (Note that this is the reason we cannot use the solutions
  // published on the net, because they assume that we are in control
  // of the content and there are no additional margins involved.)
  //
  // To get a placeholder that looks good in all cases, we
  // fill up the space between the previous and the next element.
  const childrenArray = [...draggedDOM.parentNode.children];
  // Remove the source element
  childrenArray.splice(sourceIndex, 1);
  // Also remove the placeholder that the library always inserts at the end
  childrenArray.splice(-1, 1);
  const parentRect = await draggedDOM.parentNode.getBoundingClientRect();
  const prevNode = childrenArray[destinationIndex - 1];
  const nextNode = childrenArray[destinationIndex];
  let top, bottom;
  if (prevNode) {
    const prevRect = await prevNode.getBoundingClientRect();
    top = prevRect.top + prevRect.height - parentRect.top;
  } else {
    top = 0;
  }
  if (nextNode) {
    const nextRect = await nextNode.getBoundingClientRect();
    bottom = nextRect.top - parentRect.top;
  } else {
    bottom =
      parentRect.bottom +
      (await draggedDOM.getBoundingClientRect().height) -
      parentRect.top;
  }
  return {
    clientY: top,
    clientHeight: bottom - top,
    clientX: parseFloat(
      window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
    ),
    clientWidth: draggedDOM.clientWidth,
  };
};

const defaultColumn = {
  Cell: EditableCell,
};

const DragButton = (provided) => (
  <span
    tabIndex={0}
    role="button"
    className="row-action"
    {...provided.dragHandleProps}
  >
    <Icon name={dragSVG} size="23px" />
  </span>
);
const EditableTable = (props) => {
  const {
    columns,
    data,
    updateCell,
    removeRow,
    addRowAfter,
    selectedRow,
    setSelectedRow,
    schema,
    reactSelect,
    reactBeautifulDnd,
    onMoveItem,
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateCell,
      selectedRow,
      setSelectedRow,
      schema,
      reactSelect,
      reactBeautifulDnd,
    },
    usePagination,
  );
  const { DragDropContext, Draggable, Droppable } = reactBeautifulDnd;
  if (data.length === 0) {
    addRowAfter({ key: 'Enter' }, 0, pageIndex, pageSize);
  }
  const [placeholderProps, setPlaceholderProps] = React.useState({});
  const timer = React.useRef(null);
  const onDragStart = React.useCallback(async (event) => {
    await clearTimeout(timer.current);
    const queryAttr = 'data-rbd-draggable-id';
    const domQuery = `[${queryAttr}='${event.draggableId}']`;
    const draggedDOM = await document.querySelector(domQuery);
    if (!draggedDOM) {
      return;
    }
    const sourceIndex = await event.source.index;
    await setPlaceholderProps(
      getPlaceholder(draggedDOM, sourceIndex, sourceIndex),
    );
  }, []);
  const onDragUpdate = React.useCallback(async (update) => {
    await clearTimeout(timer.current);
    await setPlaceholderProps({});
    if (!update.destination) {
      return;
    }
    const draggableId = await update.draggableId;
    const queryAttr = 'data-rbd-draggable-id';
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = await document.querySelector(domQuery);
    if (!draggedDOM) {
      return;
    }
    const sourceIndex = await update.source.index;
    const destinationIndex = await update.destination.index;
    // Wait until the animations have finished, to make it look good.
    timer.current = setTimeout(
      () =>
        setPlaceholderProps(
          getPlaceholder(draggedDOM, sourceIndex, destinationIndex),
        ),
      250,
    );
  }, []);
  React.useEffect(() => {
    gotoPage(pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const onDragEnd = React.useCallback(
    async (result) => {
      const { destination } = result;
      if (!destination) {
        return;
      }
      await clearTimeout(timer.current);
      await onMoveItem(result);
      await setPlaceholderProps({});
    },
    [onMoveItem],
  );
  // Render the UI for your table
  return (
    <>
      <DragDropContext
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
        <Table celled {...getTableProps()}>
          <Table.Header>
            {headerGroups.map((headerGroup, key) => (
              <Table.Row key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Table.HeaderCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell>{'Actions'}</Table.HeaderCell>
              </Table.Row>
            ))}
          </Table.Header>
          <Droppable droppableId="table-body">
            {(provided, snapshot) => (
              <Ref innerRef={provided.innerRef}>
                <Table.Body
                  {...getTableBodyProps()}
                  {...provided.droppableProps}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Draggable
                        draggableId={row.id}
                        index={i}
                        key={row.id.toString()}
                      >
                        {(provided, snapshot) => (
                          <Ref innerRef={provided.innerRef}>
                            <Table.Row
                              {...row.getRowProps()}
                              {...provided.draggableProps}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <Table.Cell {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </Table.Cell>
                                );
                              })}
                              <Table.Cell>
                                <div className={'row-actions'}>
                                  <span
                                    onClick={(e) => {
                                      addRowAfter(e, i, pageIndex, pageSize);
                                    }}
                                    onKeyDown={(e) => {
                                      addRowAfter(e, i, pageIndex, pageSize);
                                    }}
                                    tabIndex={0}
                                    role="button"
                                    className="row-action"
                                  >
                                    <Icon name={plusSVG} size="23px" />
                                  </span>
                                  <span
                                    onClick={(e) =>
                                      removeRow(e, i, pageIndex, pageSize)
                                    }
                                    onKeyDown={(e) =>
                                      removeRow(e, i, pageIndex, pageSize)
                                    }
                                    tabIndex={0}
                                    role="button"
                                    className="row-action"
                                  >
                                    <Icon
                                      name={deleteSVG}
                                      size="23px"
                                      color="red"
                                    />
                                  </span>
                                  <DragButton {...provided} />
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          </Ref>
                        )}
                      </Draggable>
                    );
                  })}
                  {placeholderProps.keys}
                  {provided.placeholder}
                </Table.Body>
              </Ref>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </>
  );
};

export default injectLazyLibs(['reactSelect', 'reactBeautifulDnd'])(
  EditableTable,
);

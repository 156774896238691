import React from 'react';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const EditDocSelector = (item) => (
  <Link
    title="Edit Document"
    to={flattenToAppURL(`${item['@id']}/edit`)}
    className="edit"
    aria-label="Edit"
  >
    <i className="icon write"></i>
  </Link>
);

export default EditDocSelector;

import React from 'react';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const ViewDocSelector = (item) => {
  return (
    <span>
      <Link title="View Document" to={flattenToAppURL(`${item['@id']}`)}>
        <i className="eye icon" aria-hidden="true"></i>
      </Link>
    </span>
  );
};

export default ViewDocSelector;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Container,
  Dropdown,
  Menu,
  Input,
  Segment,
  Table,
  Loader,
  Dimmer,
  Modal,
} from 'semantic-ui-react';
import { filter, find, indexOf, keys, mapValues, map, uniqBy } from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { asyncConnect } from '@plone/volto/helpers';
import {
  cut,
  copy,
  copyContent,
  deleteContent,
  listActions,
  moveContent,
  orderContent,
  sortContent,
  updateColumnsContent,
  searchContent,
} from '@plone/volto/actions';
import Indexes, { defaultIndexes } from 'volto-yc-documents/constants/Indexes';
import { Pagination, Toast } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import jwtDecode from 'jwt-decode';
import ContentStatusHistory from 'volto-yc-documents/components/ContentStatusHistory/ContentStatusHistory';
import DocumentSelector from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/DocumentSelector';
import VoltoDateTime from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/VoltoDateTime';
import ViewDocSelector from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/ViewDocSelector';
import ViewHistoryModal from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/ViewHistoryModal';
import EditDocSelector from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/EditDocSelector';
import ShowEmail from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/ShowEmail';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  contents: {
    id: 'Contents',
    defaultMessage: 'Contents',
  },
  copy: {
    id: 'Copy',
    defaultMessage: 'Copy',
  },
  cut: {
    id: 'Cut',
    defaultMessage: 'Cut',
  },
  error: {
    id: 'You can\'t paste this content here',
    defaultMessage: 'You can\'t paste this content here',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  deleteConfirm: {
    id: 'Do you really want to delete the following items?',
    defaultMessage: 'Do you really want to delete the following items?',
  },
  deleteError: {
    id: 'The item could not be deleted.',
    defaultMessage: 'The item could not be deleted.',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading',
  },
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  filter: {
    id: 'Search by CUNY ID or Name',
    defaultMessage: 'Search by CUNY ID or Name',
  },
  messageCopied: {
    id: 'Item(s) copied.',
    defaultMessage: 'Item(s) copied.',
  },
  messageCut: {
    id: 'Item(s) cut.',
    defaultMessage: 'Item(s) cut.',
  },
  messageUpdate: {
    id: 'Item(s) has been updated.',
    defaultMessage: 'Item(s) has been updated.',
  },
  messageReorder: {
    id: 'Item succesfully moved.',
    defaultMessage: 'Item succesfully moved.',
  },
  messagePasted: {
    id: 'Item(s) pasted.',
    defaultMessage: 'Item(s) pasted.',
  },
  messageWorkflowUpdate: {
    id: 'Item(s) state has been updated.',
    defaultMessage: 'Item(s) state has been updated.',
  },
  paste: {
    id: 'Paste',
    defaultMessage: 'Paste',
  },
  properties: {
    id: 'Properties',
    defaultMessage: 'Properties',
  },
  rearrangeBy: {
    id: 'Sort',
    defaultMessage: 'Sort',
  },
  rename: {
    id: 'Rename',
    defaultMessage: 'Rename',
  },
  select: {
    id: 'Select…',
    defaultMessage: 'Select…',
  },
  selected: {
    id: '{count} selected',
    defaultMessage: '{count} selected',
  },
  selectColumns: {
    id: 'Select columns to show',
    defaultMessage: 'Select columns to show',
  },
  sort: {
    id: 'sort',
    defaultMessage: 'sort',
  },
  state: {
    id: 'State',
    defaultMessage: 'State',
  },
  tags: {
    id: 'Tags',
    defaultMessage: 'Tags',
  },
  upload: {
    id: 'Upload',
    defaultMessage: 'Upload',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  publicationDate: {
    id: 'Publication date',
    defaultMessage: 'Publication date',
  },
  createdOn: {
    id: 'Created on',
    defaultMessage: 'Created on',
  },
  expirationDate: {
    id: 'Expiration date',
    defaultMessage: 'Expiration date',
  },
  id: {
    id: 'ID',
    defaultMessage: 'ID',
  },
  uid: {
    id: 'UID',
    defaultMessage: 'UID',
  },
  reviewState: {
    id: 'Review state',
    defaultMessage: 'Review state',
  },
  folder: {
    id: 'Folder',
    defaultMessage: 'Folder',
  },
  excludedFromNavigation: {
    id: 'Excluded from navigation',
    defaultMessage: 'Excluded from navigation',
  },
  objectSize: {
    id: 'Object Size',
    defaultMessage: 'Object Size',
  },
  lastCommentedDate: {
    id: 'Last comment date',
    defaultMessage: 'Last comment date',
  },
  totalComments: {
    id: 'Total comments',
    defaultMessage: 'Total comments',
  },
  creator: {
    id: 'Creator',
    defaultMessage: 'Creator',
  },
  endDate: {
    id: 'End Date',
    defaultMessage: 'End Date',
  },
  startDate: {
    id: 'Start Date',
    defaultMessage: 'Start Date',
  },
  all: {
    id: 'All',
    defaultMessage: 'All',
  },
});

/**
 * Contents class.
 * @class Contents
 * @extends Component
 */

class AdmissionsListingView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    action: PropTypes.string,
    source: PropTypes.arrayOf(PropTypes.string),
    searchContent: PropTypes.func.isRequired,
    cut: PropTypes.func.isRequired,
    copy: PropTypes.func.isRequired,
    copyContent: PropTypes.func.isRequired,
    deleteContent: PropTypes.func.isRequired,
    moveContent: PropTypes.func.isRequired,
    orderContent: PropTypes.func.isRequired,
    sortContent: PropTypes.func.isRequired,
    updateColumnsContent: PropTypes.func.isRequired,
    clipboardRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    deleteRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    updateRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    searchRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
    total: PropTypes.number.isRequired,
    pathname: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    items: [],
    action: null,
    source: null,
    index: {
      order: keys(Indexes),
      values: mapValues(Indexes, (value, key) => ({
        ...value,
        selected: indexOf(defaultIndexes, key) !== -1,
      })),
      selectedCount: defaultIndexes.length + 1,
    },
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContentsComponent
   */
  constructor(props) {
    super(props);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onSearchChangeSortOn = this.onSearchChangeSortOn.bind(this);
    this.onChangeSortOn = this.onChangeSortOn.bind(this);
    this.onChangeSortOrder = this.onChangeSortOrder.bind(this);
    this.onSearchChangeSortOrder = this.onSearchChangeSortOrder.bind(this);
    this.onChangeReviewState = this.onChangeReviewState.bind(this);
    this.onSearchChangeReviewState = this.onSearchChangeReviewState.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangePageSize = this.onChangePageSize.bind(this);
    this.onSortItems = this.onSortItems.bind(this);
    this.fetchContents = this.fetchContents.bind(this);
    this.onSelectNone = this.onSelectNone.bind(this);
    this.copy = this.copy.bind(this);
    this.paste = this.paste.bind(this);
    this.orderTimeout = null;
    this.state = {
      column: null,
      direction: null,
      selected: [],
      showDelete: false,
      showUpload: false,
      showRename: false,
      showTags: false,
      showProperties: false,
      showWorkflow: false,
      itemsToDelete: [],
      headers: [
        { label: 'ID' },
        { label: 'First Name' },
        { label: 'Last Name' },
        { label: 'Documents' },
        { label: 'Semester' },
        { label: 'Created' },
        { label: 'State' },
        { label: 'Action' },
      ],
      review_state: [
        {
          key: 'All States',
          text: 'All States',
          value: '',
        },
        { key: 'Pending', text: 'Pending', value: 'Pending ' },
        {
          key: 'duplicate',
          text: 'duplicate',
          value: 'duplicate',
        },
        { key: 'incomplete', text: 'incomplete', value: 'incomplete' },
        {
          key: 'received',
          text: 'received',
          value: 'received',
        },
      ],
      sort_by: [
        { key: 'Created', text: 'Created', value: 'created' },
        {
          key: 'ID',
          text: 'ID',
          value: 'employeeID',
        },
        { key: 'First Name', text: 'First Name', value: 'first_name' },
        {
          key: 'Last Name',
          text: 'Last Name',
          value: 'last_name',
        },
        { key: 'Semester', text: 'Semester', value: 'academic_year' },
      ],
      sort_by_order: [
        { key: 'Ascending', text: 'Ascending', value: 'ascending' },
        {
          key: 'Descending',
          text: 'Descending',
          value: 'descending',
        },
      ],
      items: this.props.items,
      filter: '',
      workflowState: 'Pending ',
      searchQuery: '',
      employeeID: '',
      sortOn: '',
      sortOrder: '',
      currentPage: 0,
      pageSize: 25,
      index: this.props.index || {
        order: keys(Indexes),
        values: mapValues(Indexes, (value, key) => ({
          ...value,
          selected: indexOf(defaultIndexes, key) !== -1,
        })),
        selectedCount: defaultIndexes.length + 1,
      },
      sort_on: this.props.sort?.on || 'created',
      sort_order: this.props.sort?.order || 'ascending',
      isClient: false,
    };

    this.filterTimeout = null;
    this.workflowStateTimeout = null;
    this.sortOnTimeout = null;
    this.sortOrderTimeout = null;
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.fetchContents();
    this.setState({ isClient: true });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (this.props.clipboardRequest.loading &&
        nextProps.clipboardRequest.loaded) ||
      (this.props.deleteRequest.loading && nextProps.deleteRequest.loaded) ||
      (this.props.updateRequest.loading && nextProps.updateRequest.loaded)
    ) {
      this.fetchContents(nextProps.pathname);
    }
    if (this.props.updateRequest.loading && nextProps.updateRequest.loaded) {
      this.props.toastify.toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(messages.success)}
          content={this.props.intl.formatMessage(messages.messageUpdate)}
        />,
      );
    }
    if (this.props.pathname !== nextProps.pathname) {
      this.setState(
        {
          currentPage: 0,
        },
        () => this.fetchContents(nextProps.pathname),
      );
    }
    if (this.props.searchRequest.loading && nextProps.searchRequest.loaded) {
      this.setState({
        items: nextProps.items,
      });
    }
    if (
      this.props.clipboardRequest.loading &&
      nextProps.clipboardRequest.error
    ) {
      this.props.toastify.toast.error(
        <Toast
          error
          title={this.props.intl.formatMessage(messages.error)}
          content={this.props.intl.formatMessage(messages.error)}
        />,
      );
    }

    if (
      this.props.clipboardRequest.loading &&
      nextProps.clipboardRequest.loaded
    ) {
      this.props.toastify.toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(messages.success)}
          content={this.props.intl.formatMessage(messages.messagePasted)}
        />,
      );
    }

    if (this.props.deleteRequest.loading && nextProps.deleteRequest.error) {
      this.props.toastify.toast.error(
        <Toast
          error
          title={this.props.intl.formatMessage(messages.deleteError)}
          content={this.props.intl.formatMessage(messages.deleteError)}
        />,
      );
    }

    if (this.props.orderRequest.loading && nextProps.orderRequest.loaded) {
      this.props.toastify.toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(messages.success)}
          content={this.props.intl.formatMessage(messages.messageReorder)}
        />,
      );
    }
  }

  onChangeFilter(event, { value }) {
    const self = this;
    clearTimeout(self.filterTimeout);
    this.setState(
      {
        filter: value,
      },
      () => {
        self.filterTimeout = setTimeout(() => {
          self.fetchContents();
        }, 200);
      },
    );
  }

  onChangeReviewState(event, { searchQuery, value }) {
    const self = this;
    clearTimeout(self.workflowStateTimeout);
    this.setState(
      {
        searchQuery: searchQuery,
        workflowState: value,
      },
      () => {
        self.workflowStateTimeout = setTimeout(() => {
          self.fetchContents();
        }, 200);
      },
    );
  }

  onSearchChangeReviewState(event, { searchQuery }) {
    const self = this;
    clearTimeout(self.workflowStateTimeout);
    this.setState(
      {
        searchQuery: searchQuery,
      },
      () => {
        self.workflowStateTimeout = setTimeout(() => {
          self.fetchContents();
        }, 200);
      },
    );
  }

  onChangeSortOrder(event, { searchQuery, value }) {
    const self = this;
    clearTimeout(self.sortOrderTimeout);
    this.setState(
      {
        searchQuery: searchQuery,
        sort_order: value,
      },
      () => {
        self.sortOrderTimeout = setTimeout(() => {
          self.fetchContents();
        }, 200);
      },
    );
  }

  onSearchChangeSortOrder(event, { searchQuery }) {
    const self = this;
    clearTimeout(self.sortOrderTimeout);
    this.setState(
      {
        searchQuery: searchQuery,
      },
      () => {
        self.sortOrderTimeout = setTimeout(() => {
          self.fetchContents();
        }, 200);
      },
    );
  }

  onChangeSortOn(event, { searchQuery, value }) {
    const self = this;
    clearTimeout(self.sortOnTimeout);
    this.setState(
      {
        searchQuery: searchQuery,
        sort_on: value,
      },
      () => {
        self.sortOnTimeout = setTimeout(() => {
          self.fetchContents();
        }, 200);
      },
    );
  }

  onSearchChangeSortOn(event, { searchQuery }) {
    const self = this;
    clearTimeout(self.sortOnTimeout);
    this.setState(
      {
        searchQuery: searchQuery,
      },
      () => {
        self.sortOnTimeout = setTimeout(() => {
          self.fetchContents();
        }, 200);
      },
    );
  }

  onSelectNone() {
    this.setState({
      selected: [],
    });
  }

  copy(event, { value }) {
    this.props.copy(value ? [value] : this.state.selected);
    this.onSelectNone();
    this.props.toastify.toast.success(
      <Toast
        success
        title={this.props.intl.formatMessage(messages.success)}
        content={this.props.intl.formatMessage(messages.messageCopied)}
      />,
    );
  }

  paste() {
    if (this.props.action === 'copy') {
      this.props.copyContent(
        this.props.source,
        getBaseUrl(this.props.pathname),
      );
    }
    if (this.props.action === 'cut') {
      this.props.moveContent(
        this.props.source,
        getBaseUrl(this.props.pathname),
      );
    }
  }

  /**
   * On change selected values (Filter)
   * @method onChangeSelected
   * @param {object} event Event object.
   * @param {string} value Filter value.
   * @returns {undefined}
   */
  onChangeSelected(event, { value }) {
    event.stopPropagation();
    const { items, selected } = this.state;

    const filteredItems = filter(selected, (selectedItem) =>
      find(items, (item) => item['@id'] === selectedItem)
        .title.toLowerCase()
        .includes(value.toLowerCase()),
    );

    this.setState({
      filteredItems,
    });
  }

  /**
   * On change page
   * @method onChangePage
   * @param {object} event Event object.
   * @param {string} value Page value.
   * @returns {undefined}
   */
  onChangePage(event, { value }) {
    this.setState(
      {
        currentPage: value,
      },
      () => this.fetchContents(),
    );
  }

  /**
   * On change page size
   * @method onChangePageSize
   * @param {object} event Event object.
   * @param {string} value Page size value.
   * @returns {undefined}
   */
  onChangePageSize(event, { value }) {
    this.setState(
      {
        pageSize: value,
        currentPage: 0,
      },
      () => this.fetchContents(),
    );
  }

  /**
   * On sort items
   * @method onSortItems
   * @param {object} event Event object
   * @param {string} value Item index
   * @returns {undefined}
   */
  onSortItems(event, { value }) {
    const values = value.split('|');
    this.setState({
      sort_on: values[0],
      sort_order: values[1],
    });
    this.props.sortContent(
      getBaseUrl(this.props.pathname),
      values[0],
      values[1],
    );
  }

  /**
   * Get field by id
   * @method getFieldById
   * @param {string} id Id of object
   * @param {string} field Field of object
   * @returns {string} Field of object
   */
  getFieldById(id, field) {
    const item = find(this.state.items, { '@id': id });
    return item ? item[field] : '';
  }

  /**
   * Fetch contents handler
   * @method fetchContents
   * @param {string} pathname Pathname to fetch contents.
   * @returns {undefined}
   */
  fetchContents(pathname) {
    if (this.state.pageSize === this.props.intl.formatMessage(messages.all)) {
      //'All'
      this.props.searchContent(getBaseUrl(pathname || this.props.pathname), {
        'path.depth': 1,
        sort_on: this.state.sort_on,
        sort_order: this.state.sort_order,
        review_state: this.state.workflowState,
        metadata_fields: '_all',
        fullobjects: 1,
        b_size: this.state.pageSize,
        ...(this.state.filter && { SearchableText2: `${this.state.filter}` }),
        ...(this.state.sort_on && { sort_on: `${this.state.sort_on}` }),
        ...(this.state.sort_order && {
          sort_order: `${this.state.sort_order}`,
        }),
        ...(this.state.workflowState && {
          review_state: `${this.state.workflowState}`,
        }),
      });
    } else {
      this.props.searchContent(getBaseUrl(pathname || this.props.pathname), {
        'path.depth': 1,
        sort_on: this.state.sort_on,
        sort_order: this.state.sort_order,
        review_state: this.state.workflowState,
        metadata_fields: '_all',
        fullobjects: 1,
        ...(this.state.filter && { SearchableText2: `${this.state.filter}` }),
        ...(this.state.sort_on && { sort_on: `${this.state.sort_on}` }),
        ...(this.state.sort_order && {
          sort_order: `${this.state.sort_order}`,
        }),
        ...(this.state.workflowState && {
          review_state: `${this.state.workflowState}`,
        }),
        b_size: this.state.pageSize,
        b_start: this.state.currentPage * this.state.pageSize,
      });
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  render() {
    const loading =
      (this.props.clipboardRequest?.loading &&
        !this.props.clipboardRequest?.error) ||
      (this.props.deleteRequest?.loading && !this.props.deleteRequest?.error) ||
      (this.props.updateRequest?.loading && !this.props.updateRequest?.error) ||
      (this.props.orderRequest?.loading && !this.props.orderRequest?.error) ||
      (this.props.searchRequest?.loading && !this.props.searchRequest?.error);
    const title = this.props.title;
    const description = this.props.description;
    const SearchID = ({ item }) => {
      const IDFunction = () => {
        this.state.filter = item;
        const self = this;
        clearTimeout(self.filterTimeout);
        this.setState(
          {
            filter: item,
          },
          () => {
            self.filterTimeout = setTimeout(() => {
              self.fetchContents();
            }, 200);
          },
        );
        return this.state.filter;
      };
      return (
        <a
          id="searchInput"
          onClick={IDFunction}
          title={`Search for ID: ${item}`}
        >
          {item}
        </a>
      );
    };

    return (
      <>
        <Container id="page-contents" className="folder-contents">
          <Dimmer.Dimmable as="div" blurring dimmed={loading}>
            <Dimmer active={loading} inverted>
              <Loader indeterminate size="massive">
                {this.props.intl.formatMessage(messages.loading)}
              </Loader>
            </Dimmer>
            <header>
              <h1>
                <a href="/admissions">Admissions</a>
              </h1>
              {description && (
                <p className="documentDescription">{description}</p>
              )}
            </header>
            <title>{title}</title>
            <div className="container">
              <article id="content">
                <section id="content-core">
                  <Segment.Group raised>
                    <Menu stackable secondary attached className="top-menu">
                      <Menu.Menu style={{ width: '45%' }}>
                        <div
                          className="ui right aligned category search item"
                          key="ui right aligned category search item"
                          style={{ width: '100%' }}
                        >
                          {/*Main Search*/}
                          <Input
                            id="searchFilter"
                            type="text"
                            placeholder={this.props.intl.formatMessage(
                              messages.filter,
                            )}
                            size="small"
                            value={this.state.filter}
                            onChange={this.onChangeFilter}
                          />
                          <div className="results" />
                        </div>
                      </Menu.Menu>
                      <Menu.Menu>
                        <div>
                          {/*Filter By State*/}
                          <Dropdown
                            icon={
                              <div
                                aria-label="Next page"
                                data-testid="page-navigation__next0button"
                              >
                                <svg
                                  aria-hidden="true"
                                  className="rpv-core__icon"
                                  focusable="false"
                                  height="16px"
                                  viewBox="0 0 24 24"
                                  width="16px"
                                >
                                  <path
                                    d="M0.541,5.627L11.666,18.2c0.183,0.207,0.499,0.226,0.706,0.043c0.015-0.014,0.03-0.028,0.043-0.043
            L23.541,5.627"
                                  ></path>
                                </svg>
                              </div>
                            }
                            clearable
                            defaultValue={this.state.review_state[1].value}
                            onChange={this.onChangeReviewState}
                            onSearchChange={this.onSearchChangeReviewState}
                            options={this.state.review_state}
                            placeholder="All States"
                            search
                            searchQuery={this.state.searchQuery}
                            selection
                            value={this.state.workflowState}
                          />
                          <div className="results" />
                        </div>
                      </Menu.Menu>
                      <Menu.Menu>
                        <div>
                          {/*Sort By*/}
                          <Dropdown
                            id="sort_by"
                            icon={
                              <div
                                aria-label="Next page"
                                data-testid="page-navigation__next0button"
                              >
                                <svg
                                  aria-hidden="true"
                                  className="rpv-core__icon"
                                  focusable="false"
                                  height="16px"
                                  viewBox="0 0 24 24"
                                  width="16px"
                                >
                                  <path
                                    d="M0.541,5.627L11.666,18.2c0.183,0.207,0.499,0.226,0.706,0.043c0.015-0.014,0.03-0.028,0.043-0.043
            L23.541,5.627"
                                  ></path>
                                </svg>
                              </div>
                            }
                            clearable
                            defaultValue={this.state.sort_by[0].value}
                            onChange={this.onChangeSortOn}
                            onSearchChange={this.onSearchChangeSortOn}
                            options={this.state.sort_by}
                            placeholder={this.state.sort_by[0].value}
                            search
                            searchQuery={this.state.searchQuery}
                            selection
                            value={this.state.sort_on}
                          />
                          <div className="results" />
                        </div>
                      </Menu.Menu>
                      <Menu.Menu>
                        <div>
                          {/*Sort Order*/}
                          <Dropdown
                            id="sort_order"
                            icon={
                              <div
                                aria-label="Next page"
                                data-testid="page-navigation__next0button"
                              >
                                <svg
                                  aria-hidden="true"
                                  className="rpv-core__icon"
                                  focusable="false"
                                  height="16px"
                                  viewBox="0 0 24 24"
                                  width="16px"
                                >
                                  <path
                                    d="M0.541,5.627L11.666,18.2c0.183,0.207,0.499,0.226,0.706,0.043c0.015-0.014,0.03-0.028,0.043-0.043
            L23.541,5.627"
                                  ></path>
                                </svg>
                              </div>
                            }
                            clearable
                            defaultValue={this.state.sort_by_order[0].value}
                            onChange={this.onChangeSortOrder}
                            onSearchChange={this.onSearchChangeSortOrder}
                            options={this.state.sort_by_order}
                            placeholder={this.state.sort_by_order[0].value}
                            search
                            searchQuery={this.state.searchQuery}
                            selection
                            value={this.state.sort_order}
                          />
                          <div className="results" />
                        </div>
                      </Menu.Menu>
                    </Menu>
                    <Table celled compact attached filter>
                      <Table.Header>
                        <Table.Row>
                          {this.state.headers &&
                            this.state.headers.map((header) => (
                              <Table.HeaderCell>
                                <div>{header.label}</div>
                              </Table.HeaderCell>
                            ))}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.items &&
                          this.state.items.map((item) => (
                            <Table.Row>
                              <Table.Cell>
                                <div>
                                  <SearchID item={item?.employeeID} />
                                  <br />
                                  <ShowEmail token={this.props.token} />
                                </div>
                              </Table.Cell>
                              <Table.Cell>
                                <div>{item?.first_name}</div>
                              </Table.Cell>
                              <Table.Cell>
                                <div>{item?.last_name}</div>
                              </Table.Cell>
                              <Table.Cell>
                                {item?.preview ? (
                                  <ol>
                                    {item?.attachment_type && (
                                      <li key="file1">
                                        <DocumentSelector
                                          type={item?.attachment_type}
                                          file={item?.attachment_file}
                                        />
                                      </li>
                                    )}
                                    {item?.attachment_type1 && (
                                      <li key="file2">
                                        <DocumentSelector
                                          type={item?.attachment_type1}
                                          file={item?.attachment_file1}
                                        />
                                      </li>
                                    )}
                                    {item?.attachment_type2 && (
                                      <li key="file3">
                                        <DocumentSelector
                                          type={item?.attachment_type2}
                                          file={item?.attachment_file2}
                                        />
                                      </li>
                                    )}
                                    {item?.attachment_type3 && (
                                      <li key="file4">
                                        <DocumentSelector
                                          type={item?.attachment_type3}
                                          file={item?.attachment_file3}
                                        />
                                      </li>
                                    )}
                                    {item?.attachment_type4 && (
                                      <li key="file5">
                                        <DocumentSelector
                                          type={item?.attachment_type4}
                                          file={item?.attachment_file4}
                                        />
                                      </li>
                                    )}
                                  </ol>
                                ) : (
                                  <ol>
                                    {item?.attachment_type && (
                                      <li>{item?.attachment_type.title}</li>
                                    )}
                                    {item?.attachment_type1 && (
                                      <li>{item?.attachment_type1.title}</li>
                                    )}
                                    {item?.attachment_type2 && (
                                      <li>{item?.attachment_type2.title}</li>
                                    )}
                                    {item?.attachment_type3 && (
                                      <li>{item?.attachment_type3.title}</li>
                                    )}
                                    {item?.attachment_type4 && (
                                      <li>{item?.attachment_type4.title}</li>
                                    )}
                                  </ol>
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {item?.academic_year?.title ||
                                  item?.academic_year?.token}
                              </Table.Cell>
                              <Table.Cell>
                                <div>
                                  <VoltoDateTime field={item?.created} />
                                  {/*{this.props.userId}*/}
                                </div>
                              </Table.Cell>
                              <Table.Cell>
                                <div>{item?.review_state}</div>
                              </Table.Cell>
                              <Table.Cell>
                                <ViewDocSelector {...item} />
                                <span></span>
                                {item?.preview ? (
                                  <span>
                                    <ContentStatusHistory {...item} />
                                    <ViewHistoryModal {...item} />
                                    <span></span>
                                    <EditDocSelector {...item} />
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                    <div>
                      <Pagination
                        current={this.state.currentPage}
                        total={Math.ceil(
                          this.props.total / this.state.pageSize,
                        )}
                        pageSize={this.state.pageSize}
                        pageSizes={[25, 50, 75, 100]}
                        onChangePage={this.onChangePage}
                        onChangePageSize={this.onChangePageSize}
                      />
                    </div>
                  </Segment.Group>
                </section>
              </article>
            </div>
          </Dimmer.Dimmable>
        </Container>
      </>
    );
  }
}

const DragDropConnector = (props) => {
  const { DragDropContext } = props.reactDnd;
  const HTML5Backend = props.reactDndHtml5Backend.default;

  const DndConnectedContents = React.useMemo(
    () => DragDropContext(HTML5Backend)(AdmissionsListingView),
    [DragDropContext, HTML5Backend],
  );

  return <DndConnectedContents {...props} />;
};

export const __test__ = compose(
  injectIntl,
  injectLazyLibs(['toastify', 'reactDnd']),
  connect(
    (store, props) => {
      return {
        userId: store.userSession.token
          ? jwtDecode(store.userSession.token).sub
          : '',
        token: store.userSession.token,
        items: store.search.items,
        sort: store.content.update.sort,
        index: store.content.updatecolumns.idx,
        breadcrumbs: store.breadcrumbs.items,
        total: store.search.total,
        searchRequest: {
          loading: store.search.loading,
          loaded: store.search.loaded,
        },
        pathname: props.location.pathname,
        action: store.clipboard.action,
        source: store.clipboard.source,
        clipboardRequest: store.clipboard.request,
        deleteRequest: store.content.delete,
        updateRequest: store.content.update,
        objectActions: store.actions.actions.object,
        orderRequest: store.content.order,
      };
    },
    {
      searchContent,
      cut,
      copy,
      copyContent,
      deleteContent,
      listActions,
      moveContent,
      orderContent,
      sortContent,
      updateColumnsContent,
    },
  ),
)(AdmissionsListingView);

export default compose(
  injectIntl,
  connect(
    (store, props) => {
      return {
        userId: store.userSession.token
          ? jwtDecode(store.userSession.token).sub
          : '',
        token: store.userSession.token,
        items: store.search.items,
        sort: store.content.update.sort,
        index: store.content.updatecolumns.idx,
        breadcrumbs: store.breadcrumbs.items,
        total: store.search.total,
        searchRequest: {
          loading: store.search.loading,
          loaded: store.search.loaded,
        },
        pathname: props.location.pathname,
        action: store.clipboard.action,
        source: store.clipboard.source,
        clipboardRequest: store.clipboard.request,
        deleteRequest: store.content.delete,
        updateRequest: store.content.update,
        objectActions: store.actions.actions.object,
        orderRequest: store.content.order,
      };
    },
    {
      searchContent,
      cut,
      copy,
      copyContent,
      deleteContent,
      listActions,
      moveContent,
      orderContent,
      sortContent,
      updateColumnsContent,
    },
  ),
  asyncConnect([
    {
      key: 'actions', // Dispatch async/await to make the operation syncronous, otherwise it returns
      // before the promise is resolved
      promise: async ({ location, store: { dispatch } }) =>
        await dispatch(listActions(getBaseUrl(location.pathname))),
    },
  ]),
  injectLazyLibs(['toastify', 'reactDnd', 'reactDndHtml5Backend']),
)(DragDropConnector);

import React from 'react';

const FieldSelector = ({ label, field }) => {
  if (label && field) {
    return (
      <span>
        <h2>
          {field && label}
          <br />
        </h2>
        {field && <p>{field}</p>}
        <br />
      </span>
    );
  }
  return <span>{field && field}</span>;
};

export default FieldSelector;

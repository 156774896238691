import React from 'react';
import { Container, Form, Message, Segment, Grid } from 'semantic-ui-react';
import DocsPortalImage from 'volto-yc-graduateapplication/components/documents-portal-unauthenticated.svg';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
const Unauthorized = () => {
  let location = useLocation();
  return (
    <Container>
      <Form>
        <title>Protected Resource</title>
        <h1>Protected Resource</h1>
        <p className="description">
          You are trying to access a protected resource, please{' '}
          <strong>login</strong> first.
        </p>
        <br />
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment.Group raised>
                <Message info>
                  <Message.Header>
                    You must log in to upload new documents.
                  </Message.Header>
                  <span>
                    Current Students/Faculty/Staff must <strong>log in</strong>{' '}
                    using your{' '}
                    <a href="https://www.york.cuny.edu/myid">
                      York College Network Account
                    </a>{' '}
                    username (e.g. jdoe7). <br /> Prospective, newly admitted,
                    or other students/users that are not enrolled this semester
                    must <strong>register</strong> and log in with their
                    personal email address.
                  </span>
                </Message>{' '}
              </Segment.Group>
              <br />
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Link
                      className="ui button primary fluid"
                      to={`/login?return_url=${location.pathname}`}
                    >
                      Login
                    </Link>
                  </Grid.Column>
                  <Grid.Column>
                    <Link className="ui button secondary fluid" to="/register">
                      Register
                    </Link>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <FormattedMessage
                      id="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
                      defaultMessage="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
                      values={{
                        site_admin: (
                          <Link to="/contact-form">
                            <FormattedMessage
                              id="Site Administration"
                              defaultMessage="Site Administration"
                            />
                          </Link>
                        ),
                      }}
                    />
                    <br />
                    <FormattedMessage
                      id="Thank you."
                      defaultMessage="Thank you."
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>{' '}
            <Grid.Column textAlign="center">
              <img
                src={DocsPortalImage}
                alt={DocsPortalImage}
                style={{ width: '50%' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
};
export default Unauthorized;

import moment from 'moment';
import { Container, Form, Table, Grid } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PDFViews from 'volto-yc-documents/components/PDFViews/PDFViews';
import ContentStatusHistory from 'volto-yc-documents/components/ContentStatusHistory/ContentStatusHistoryCTV';
import YCImageView from 'volto-yc-documents/components/YCImageView/YCImageView';
import Voting from 'volto-yc-voting/components/Voting/Voting';
import { Link } from 'react-router-dom';
import DocsPortalImage from 'volto-yc-documents/components/documents-portal-login.svg';
import { FormattedMessage } from 'react-intl';
import DefaultLoader from 'volto-yc-graduateapplication/components/ContentTypesViews/DefaultLoader';

const VerticalTable = (content) => {
  const data = [
    {
      label: 'Ability to adapt and change',
      value: content.adapt.token.toString(),
    },
    {
      label: 'Awareness of self and others',
      value: content.awareness.token.toString(),
    },
    { label: 'Maturity', value: content.maturity.token.toString() },
    {
      label: 'Openness to feedback and constructive criticism',
      value: content.openness.token.toString(),
    },
    {
      label: 'Interpersonal skills (with superiors/executives)',
      value: content.interpersonal.token.toString(),
    },
    { label: 'Confidence', value: content.confidence.token.toString() },
    {
      label: 'Initiative/Self-Motivation',
      value: content.initiative.token.toString(),
    },
    {
      label: 'Collaboration/Teamwork',
      value: content.collaboration.token.toString(),
    },
    {
      label: 'Critical Thinking Skills',
      value: content.thinking.token.toString(),
    },
    {
      label: 'Intellectual Curiosity',
      value: content.curiosity.token.toString(),
    },
    {
      label: 'Problem Solving Skills',
      value: content.problemSolvingSkills.token.toString(),
    },
  ];
  return (
    <Table celled compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Label</Table.HeaderCell>
          <Table.HeaderCell>Selection</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {data &&
        data.map((element) => (
          <Table.Body>
            <Table.Row>
              <Table.Cell>{element.label}</Table.Cell>
              <Table.Cell>{element.value}</Table.Cell>
            </Table.Row>
          </Table.Body>
        ))}
    </Table>
  );
};

const FieldSelector = ({ label, field }) => {
  if (label && field) {
    return (
      <div>
        <br />
        <strong> {field && label} </strong>: {field && field}
        <br />
      </div>
    );
  }
  return <span>{field && field}</span>;
};
const AttachmentSelector = ({ type, file }) => {
  const content = useSelector((store) => store.content.data);
  return (
    <div>
      {content.preview === 'True' ? (
        <div>
          <p>
            <strong> Attachment: </strong>
            {(type && type?.title) ||
              (type && type) ||
              (type && type?.toString()) ||
              (type && type?.token)}{' '}
            - <a href={file?.download}>{file?.filename}</a>
          </p>
          {file ? (
            <PDFViews file={file?.download} type={file['content-type']} />
          ) : (
            <YCImageView file={file?.scales?.preview?.download} />
          )}{' '}
        </div>
      ) : (
        <p>
          <strong> Attachment: </strong>
          {(type && type?.title) ||
            (type && type) ||
            (type && type?.toString()) ||
            (type && type?.token)}{' '}
          - {file?.filename}
        </p>
      )}
    </div>
  );
};
const print = () => window.print();
const Recommendations = (props) => {
  const { content } = props;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return content.id ? (
    <div>
      {content.candidateName ? (
        <Container className="view-wrapper">
          {!loaded ? (
            <DefaultLoader active={!loaded} />
          ) : (
            <Form>
              <title>Recommendations</title>
              <h1 className="documentFirstHeading">{content.refereeName}</h1>
              <Voting />
              <span className="contentStatusHistory">
                <ContentStatusHistory {...content} />
              </span>
              <span> | </span>
              <span>
                <a href="#" onClick={print}>
                  Print
                </a>
              </span>
              <section id="content-core">
                <FieldSelector
                  label={'Applicant Name'}
                  field={content.candidateName}
                />{' '}
                {content.refereeName && (
                  <FieldSelector
                    label={'Referee Name'}
                    field={content.refereeName}
                  />
                )}
                {content.email && (
                  <FieldSelector label={'Email'} field={content.email} />
                )}
                {content.phone && (
                  <FieldSelector label={'Telephone'} field={content.phone} />
                )}
                {content.address && (
                  <FieldSelector
                    label={'Mailing Address'}
                    field={content.address}
                  />
                )}
                {content.capacity && (
                  <FieldSelector
                    label={
                      'Knowledge of Applicant: What capacity have you known the Applicant?'
                    }
                    field={content.capacity}
                  />
                )}
                {content.howLong && (
                  <FieldSelector
                    label={
                      'How long have you known the Applicant? (years/months)'
                    }
                    field={content.howLong}
                  />
                )}
                {content.knowledge && (
                  <FieldSelector label={'Other'} field={content.knowledge} />
                )}
                <VerticalTable {...content} />
                {content.comments && (
                  <FieldSelector
                    label={'Additional comments'}
                    field={content.comments}
                  />
                )}
                {content.letter_recommendation && (
                  <AttachmentSelector
                    type={'Formal letter of recommendation'}
                    file={content.letter_recommendation}
                  />
                )}
                {content.acceptanceAR && (
                  <FieldSelector
                    label={'For Academic Referees Only'}
                    field={content.acceptanceAR.token.toString()}
                  />
                )}
                {content.acceptanceNR && (
                  <FieldSelector
                    label={'For Non-Academic Referees Only'}
                    field={content.acceptanceNR.token.toString()}
                  />
                )}
                <br />
              </section>
              <br />
              <p>Created {moment(content.created).format('ll')}</p>
              <p>Last modified {moment(content.modified).fromNow('ll')} ago</p>
            </Form>
          )}
        </Container>
      ) : (
        <Container>
          <title>Thanks</title>
          <h1>Thanks</h1>
          <p className="description">Thank You for your Recommendation.</p>
        </Container>
      )}
    </div>
  ) : (
    <Container>
      <title>Protected Resource</title>
      <h1>Protected Resource</h1>
      <p className="description">
        You are trying to access a protected resource, you are not authorized to
        view this resource.
      </p>
      <br />
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <FormattedMessage
                    id="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
                    defaultMessage="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
                    values={{
                      site_admin: (
                        <Link to="/contact-form">
                          <FormattedMessage
                            id="Site Administration"
                            defaultMessage="Site Administration"
                          />
                        </Link>
                      ),
                    }}
                  />
                  <br />
                  <FormattedMessage
                    id="Thank you."
                    defaultMessage="Thank you."
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>{' '}
          <Grid.Column textAlign="center">
            <img
              src={DocsPortalImage}
              alt={DocsPortalImage}
              style={{ width: '50%' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Recommendations;

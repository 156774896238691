import React from 'react';
import { TextArea, Button, Form, Modal } from 'semantic-ui-react';
import Workflow from 'volto-yc-documents/components/ContentStatusHistory/Workflow';
import {
  transitionWorkflow,
  getWorkflow,
} from '@plone/volto/actions/workflow/workflow';
import { raw } from 'volto-yc-documents/actions';
import { useDispatch } from 'react-redux';
import {
  getContent,
  updateContent,
} from '@plone/volto/actions/content/content';
import { Link } from 'react-router-dom';

const ContentStatusHistory = (item) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    const transitionValue = document.getElementById('transitionValue')
      .innerHTML;
    await dispatch(raw(transitionValue, comment, true));
    await setOpen(false);
    dispatch(updateContent(item['@id'], item.review_state));
  };
  return (
    <Modal
      className="view-wrapper"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      trigger={
        <Link title="Change State">
          <i aria-hidden="true" className="check circle outline icon"></i>
        </Link>
      }
    >
      <Modal.Header>
        <h1>Change State</h1>
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <div className="Transition">
            <Workflow
              pathname={item['@id']}
              transitionWorkflow={transitionWorkflow}
              getWorkflow={getWorkflow}
              getContent={getContent}
            />
          </div>
          <br />
          <div className="Comments">
            <p>
              Comments below are added to the history and sent by email to the
              creator if enabled for this transition.
            </p>
            <TextArea
              id="comment"
              type="text"
              name="comment"
              cols="60"
              rows="5"
              value={comment}
              onChange={(event) => {
                event.preventDefault();
                setComment(event.target.value);
              }}
              style={{ border: 'solid #eee 1px', marginBottom: '1em' }}
            />
          </div>
          <Modal.Actions>
            <Button type="submit" onClick={handleSubmit} positive>
              {' '}
              Save{' '}
            </Button>
            <Button
              type="cancel"
              onClick={() => setOpen(false)}
              negative
            >
              {' '}
              Cancel{' '}
            </Button>
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ContentStatusHistory;

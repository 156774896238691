/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import {Container, List, Segment, Grid} from 'semantic-ui-react';
import {FormattedMessage, defineMessages, injectIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {UniversalLink} from '@plone/volto/components';
import config from '@plone/volto/registry';
import social01 from './ico-social01.svg';
import social03 from './ico-social03.svg';
import social04 from './ico-social04.svg';
import social07 from './ico-social07.svg';
import social08 from './ico-social08.svg';
import cuny from './cuny-white2018.png';


const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({intl}) => {
  const {settings} = config;
  const lang = useSelector((state) => state.intl.locale);
  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      color="grey"
      textAlign="left"
      id="footer"
    >
      <Container>
        <div basic inverted color="grey" className="discreet">
          <Grid className="ui stackable four column grid">
            <Grid.Column>
              <p>
                <a href="https://www.york.cuny.edu/about/consumer-information">Consumer Information</a><br/>
                <a href="https://www.york.cuny.edu/contact-york" accessKey="9">Contact York</a><br/>
                <a href="https://www.york.cuny.edu/president/diversity-and-compliance">Diversity and Compliance</a><br/>
                <a href="https://www.york.cuny.edu/human-resources/office-of-human-resources/jobs">Employment</a><br/>
                <a href="https://www.york.cuny.edu/public-safety">Public Safety</a>
              </p>
            </Grid.Column>
            <Grid.Column >
              <p>
                <a href="https://www.york.cuny.edu/portal_college">Directory</a><br/>
                <a href="https://www.york.cuny.edu/accessibility-info" accessKey="0">Accessibility</a><br/>
                <a href="https://www.york.cuny.edu/it/webteam">Website</a><br/>
                <FormattedMessage
                  id="{copyright} {current_year}"
                  defaultMessage="{copyright} {current_year}"
                  values={{
                    copyright: (
                      <abbr title={intl.formatMessage(messages.copyright)}>©</abbr>
                    ),
                    current_year: new Date().getFullYear(),
                    plonefoundation: (
                      <a href="https://www.york.cuny.edu/it/webteam/website-privacy-policy">
                        <FormattedMessage
                          id="Plone Foundation"
                          defaultMessage="Plone Foundation"
                        />
                      </a>
                    ),
                  }}
                />
              </p>

            </Grid.Column>
            <Grid.Column width={6}  textAlign='right'>
              <p>York College / CUNY<br/> 94 - 20 Guy R. Brewer Blvd.<br/> Jamaica, NY 11451<br/> <abbr
                title="Phone">P: </abbr>718-262-2000</p>
              <p id="yc-social">
                <a href="https://www.facebook.com/pages/York-College-CUNY/175813186163" target="_blank">
                  <img src={social01} alt="facebook" width="30" height="30"/></a>&nbsp;
                <a href="https://twitter.com/#!/YorkCollegeCUNY" rel="noopener" target="_blank">
                  <img src={social03} alt="twitter" width="30" height="30"/></a>&nbsp;
                <a href="https://www.instagram.com/york_college_cuny/" rel="noopener" target="_blank">
                  <img src={social08} alt="instagram" width="30" height="30"/></a>&nbsp;
                <a href="https://www.youtube.com/user/YorkTelevision" rel="noopener" target="_blank">
                  <img src={social04} alt="youtube" width="30" height="30"/></a>&nbsp;
                <a href="/about/directions">
                  <img src={social07} alt="Directions" width="30" height="30"/></a>&nbsp;
                <a href="https://cuny.edu/" id="cuny" rel="noopener" target="_blank">
                  <img src={cuny} alt="CUNY"/></a>
              </p>
            </Grid.Column>
          </Grid>
        </div>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property
  {
    Object
  }
  propTypes Property types.
 * @static
 */
Footer.propTypes =
  {
    /**
     * i18n object
     */
  }
;

export default injectIntl(Footer);

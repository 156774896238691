import React from 'react';
import DataGridFieldWidget from 'volto-yc-datagridfield/Widgets/DataGridFieldWidget';

const ItemSchema = {
  title: "Employer's Information",
  description: 'Name Phone Street City State Zip',
  properties: {
    employer: {
      title: 'Employer Name',
      factory: 'Text line (String)',
      type: 'string',
    },
    employerPhone: {
      title: 'Employer Phone',
      factory: 'Text line (String)',
      type: 'string',
    },
    street: {
      title: 'Street',
      factory: 'Text line (String)',
      type: 'string',
    },
    city: {
      title: 'City',
      factory: 'Text line (String)',
      type: 'string',
    },
    state: {
      title: 'State',
      factory: 'Text line (String)',
      type: 'string',
    },
    zip: {
      title: 'Postal Code',
      factory: 'Text line (String)',
      type: 'string',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: "Employer's Information",
      fields: ['employer', 'employerPhone', 'street', 'city', 'state', 'zip'],
    },
  ],
  required: [],
};
const EmployerWidget = (props) => {
  return (
    <DataGridFieldWidget
      schema={ItemSchema}
      {...props}
      value={(props.value || props.default) || (props.value?.items || props.default?.items) || []}
      onChange={((id, value) => props.onChange(id, value)) || ((id, value) => props.onChange(id, {items: value}))}
    />
  );
};

export default EmployerWidget;

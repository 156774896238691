const applyConfig = (config) => {
  config.settings.navDepth = 3;
  config.settings.image_crop_aspect_ratios = [
    {
      label: '2:3',
      ratio: 2 / 3,
    },
    {
      label: '16:9',
      ratio: 16 / 9,
    },
  ];
  return config;
};
export default applyConfig;

import React from 'react';
import { Link } from 'react-router-dom';
import History from 'volto-yc-documents/components/History/History';
import { Modal } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const ViewHistoryModal = (item) => (
  <Modal
    closeIcon={<i className="close icon" aria-label="true"></i>}
    trigger={
      <Link title="View History">
        <i className="clock outline icon" aria-label="true"></i>
      </Link>
    }
    scrolling
  >
    <Modal.Header>
      <h1>
        {item?.last_name}, {item?.first_name} - {item?.employeeID}
      </h1>
    </Modal.Header>
    <Modal.Content scrolling>
      <History
        location={{
          pathname: flattenToAppURL(item['@id']),
        }}
      />
    </Modal.Content>

    <br />
  </Modal>
);

export default ViewHistoryModal;

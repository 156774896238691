import './react-table-widget.css';
import { Segment, Grid } from 'semantic-ui-react';
import EditableTable from './EditableTable';
import React from 'react';
import { v4 as uuid } from 'uuid';

const DataGridFieldWidget = (props) => {
  let { schema, value, onChange, id, fieldSet } = props;

  const header_columns = schema.fieldsets[0].fields.map((field) => {
    return { Header: schema.properties[field]?.title, accessor: field };
  });

  const tablecolumns = React.useMemo(
    () => [...header_columns],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [originalData] = React.useState(value);
  const [hasModifiedData, setHasModifiedData] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(0);
  const updateCell = async (rowIndex, columnId, updateValue) => {
    await setHasModifiedData(true);
    const newvalue = value.map((v, i) =>
      i !== rowIndex ? v : { ...v, [columnId]: updateValue },
    );
    await onChange(id, newvalue);
  };

  const removeRow = async (event, rowIndex, pageIndex, pageSize) => {
    await setHasModifiedData(true);
    if (event.type === 'click' || event.key === 'Enter') {
      await setSelectedRow(pageIndex * pageSize + rowIndex);
      const newvalue = value.filter(
        (v, i) => i !== pageIndex * pageSize + rowIndex,
      );
      await onChange(id, newvalue);
    }
  };

  const addRowAfter = async (event, rowIndex, pageIndex, pageSize) => {
    await setHasModifiedData(true);
    if (event.type === 'click' || event.key === 'Enter') {
      let newRowValue = {};
      schema.fieldsets[0].fields.forEach((field) => {
        newRowValue[field] = '';
      });
      await setSelectedRow(pageIndex * pageSize + rowIndex + 1);
      const newvalue = [
        ...value.slice(0, pageIndex * pageSize + rowIndex + 1),
        { '@id': uuid(), ...newRowValue },
        ...value.slice(pageIndex * pageSize + rowIndex + 1),
      ];
      await onChange(id, newvalue);
    }
  };
  const onMoveItem = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    const newValue = [...value];
    const [movedNewValue] = newValue.splice(source.index, 1);
    newValue.splice(destination.index, 0, movedNewValue);
    await onChange(id, newValue);
  };

  return (
    <div className="inline field">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <div className="wrapper">
              <label id={`fieldset-${fieldSet}-field-label-${id}`}>
                {schema.title}
              </label>
            </div>
          </Grid.Column>
        </Grid.Row><Grid.Row>
          <Grid.Column>
            <p className="help">{schema.description}</p>
          </Grid.Column>
      </Grid.Row><Grid.Row>
          <Grid.Column textAlign="center">
            <EditableTable
              columns={tablecolumns}
              data={value}
              updateCell={updateCell}
              removeRow={removeRow}
              addRowAfter={addRowAfter}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              schema={schema}
              onMoveItem={onMoveItem}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default DataGridFieldWidget;

/**
 * Breadcrumbs reducer.
 * @module reducers/breadcrumbs/breadcrumbs
 */

import { map } from 'lodash';
import { flattenToAppURL } from '@plone/volto/helpers';

import { ITEM_COUNT } from 'volto-yc-documents/constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  root: null,
  loaded: false,
  loading: false,
};

/**
 * Breadcrumbs reducer.
 * @function breadcrumbs
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function item_count(state = initialState, action = {}) {
  switch (action.type) {
    case `${ITEM_COUNT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${ITEM_COUNT}_SUCCESS`:
      return {
        ...state,
        error: null,
        items: map(action.result.items, (item) => ({
          title: item.title,
          '@id': item['@id'],
          description: item.description,
        })),
        root: flattenToAppURL(action.result.root),
        loaded: true,
        loading: false,
      };
    case `${ITEM_COUNT}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}

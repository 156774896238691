import { RAW } from 'volto-yc-documents/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

export default function raw(urls, comment, include_children = false) {
  return {
    type: RAW,
    request:
      typeof urls === 'string'
        ? {
            op: 'post',
            path: flattenToAppURL(urls),
            data: { comment, include_children },
          }
        : urls.map((url) => ({
            op: 'post',
            path: flattenToAppURL(url),
            data: { comment, include_children },
          })),
  };
}
